const pedidos = (state = { busqueda: { cliente: null, pendientes: 'todos', fecha: '30' }, resultados: { pedidos: [], totalEntregados: 0, totalPedidos: 0, totalPendientes: 0} }, action) => {
    switch (action.type) {
        case 'SET_LP_BUSQUEDA':
            return {
                ...state, 
                busqueda: {
                    ...action.busqueda
                }
            }
        case 'SET_LP_RESULTADOS_BUSQUEDA':
            return {
                ...state,
                resultados: {
                    ...action.resultados
                }
            }
        default:
            return state
    }
}

export default pedidos
