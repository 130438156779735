import React, { Component } from 'react'
import { Form, Table, Button } from 'react-bootstrap'
import commons from '../../common'

class ListarCliente extends Component {

    state={
        permiso: true,
        listaCliente : []
    }

    componentDidMount() {
        if(!commons.checkPermission(this.props.userData.permisos,['Administrador'])){
            this.setState({permiso : false})
        }else{
            this.getClientes()
        }
        
    }

    refresh = ()=>{
        this.getClientes()
    }

    //Obtener lista de Clientes
    getClientes = async () => {
        const body = ({ id: 'getClientes' })
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            this.setState({ listaCliente: response.cliente })
        } else {
            alert("Error ")
        }
    }

    handleEliminar = async (idCliente) =>{
        const body = ({ id: 'borrarCliente', idCliente: idCliente })
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            this.getClientes()
        } else {
            alert("Error ")
        }

    }


    render() {
        let { listaCliente } = this.state
        return (

            <div>
                <div className="text-center mt-2 mb-2">
                    <h5 className="text-primary">Listado de clientes</h5>
                </div>

                {!this.state.permiso?
                    <div className="text-center mt-4">
                        <small className="text-muted">No posee permisos para visualizar los clientes</small>
                    </div>
                :null}

                <div className="body"> 
                <div className="text-center mt-2 mb-2">
                    <Button onClick={()=>{this.refresh()}} variant="info" size="sm" block>Refrescar</Button>
                </div>

                    <Form>
                        <Form.Group>
                            <Table striped bordered hover size="sm" className="justify-content-around">
                                <thead>
                                    <tr>
                                        <th>CUIT</th>
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Email</th>
                                        <th>Teléfono</th>
                                        <th>Vendedor</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listaCliente.map(n => {
                                        return (
                                            <tr key={n.idCliente}>
                                                <td>{n.cuit}</td>
                                                <td>{n.nombre}</td>
                                                <td>{n.descripcion}</td>
                                                <td>{n.email}</td>
                                                <td>{n.telefono}</td>
                                                <td>{n.nombreVendedor}</td>
                                                <td>
                                                    <Button onClick={()=>{this.handleEliminar(n.idCliente)}} variant="danger" size="sm" block disabled={!this.state.permiso}>Borrar</Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Form.Group>
                    </Form>

                </div>






            </div>

        )
    }
}

export default ListarCliente
