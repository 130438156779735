import React,{Component} from 'react'
import commons from '../../common'

class PendientesPorArticulo extends Component {

    state = {
        permiso: false,
        pendientes: []
    }
    componentDidMount(){
        if (commons.checkPermission(this.props.userData.permisos,['PendientesPorArticulo', 'Administrador'])) {
            this.setState({permiso: true})
            this.obtenerPendientesPorTalle()
        }    
    }

    obtenerPendientesPorTalle = async () => {
        const body = {  
            id: 'pendientesPorTalle',
            idUsuario : this.props.userData.idUsuario
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({pendientes : response.pendientes})
        } else {
            alert("Error al modificar precio")
        }
    }

    render(){
        return(
            <div>
                <div className="mt-2 text-center"><h5 className="text-primary">Pendientes por artículo</h5></div>
                {!this.state.permiso?
                <div className="mt-4 text-center"><small className="text-muted">Su usuario no tiene permisos para ver esta sección</small></div>:null}

                <div className="container">
                    <div className="row bg-light p-2 text-center">
                        <div className="col">Artículo</div>
                        <div className="col">Marca</div>
                        <div className="col">Talle</div>
                        <div className="col">Pendiente</div>
                    </div>
                    {this.state.pendientes.length>0?
                    this.state.pendientes.map(n=>{
                        let key = n.idArticulo+":"+n.talle
                        return (
                            <div className="row text-center" key={key}>
                                <div className="col"><small>{n.nombre}</small></div>
                                <div className="col"><small>{n.marca}</small></div>
                                <div className="col"><small>{n.talle}</small></div>
                                <div className="col"><small>{n.pendiente}</small></div>
                            </div>
                        )
                    }) 
                    :null}
                </div>


            </div>
        )
    }
}

export default PendientesPorArticulo
