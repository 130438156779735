import React,{Component,Fragment} from 'react'
import commons from '../../common'
import { withRouter } from 'react-router-dom'
import { Table } from 'react-bootstrap'


class VerRemito extends Component{

    state = {
        permiso: true,
        remito: '',
        itemsRemito: []
    }

    componentDidMount(){
        if(!commons.checkPermission(this.props.userData.permisos,['VerRemito','Administrador'])){
            this.setState({permiso : false})
        } else {
            this.getDetalleRemito()
        }
    }

    
    getDetalleRemito = async () => {
        const body = { 
            id: 'verRemito',
            idRemito : this.props.match.params.id
        }

        let response = await commons.doPost(this.props.token,body)

        if (response.result === 'ok') {
            let idPedido = response.itemsRemito.length>0? response.itemsRemito[0].idPedido: '1'
            this.setState({itemsRemito: response.itemsRemito, idPedido: idPedido, remito: response.itemsRemito[0].idRemito})

        } else {
            alert("Error ")
        }
    }

    volver = (id) => {
        this.props.history.push("/listarRemitos/"+this.state.idPedido)
    }

    render() {

        return(
            <div className="mt-2 mb-2 text-center">
                <div className="text-primary">
                    <h5>Detalle para remito {this.state.remito}</h5>
                </div>

                {!this.state.permiso?

                <div className="text-center mt-4">
                    <small className="text-muted">No posee permisos para ver detalle de remitos</small>
                </div>
                        : 
                            <div>
                            
                                <button className="btn btn-sm btn-primary m-2" onClick={this.volver}>Volver</button>
                                {this.state.itemsRemito.length>0?
                                <p className="m-2 text-primary">Fecha remito: {this.state.itemsRemito[0].fechaRemito.split(" ")[0]}</p>
                                : null}
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Marca</th>
                                        <th>Artículo</th>
                                        <th>Entregado</th>
                                        {commons.checkPermission(this.props.userData.permisos,['VerTotales', 'Administrador']) ?
                                        <Fragment>
                                        <th className="text-center">$ unitario</th>
                                        <th className="text-center">$ Total</th>
                                           </Fragment>
                                        :null}
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                    this.state.itemsRemito?
                                        this.state.itemsRemito.map(n=>{
                                            let key = n.idRemito+":"+n.nombreArticulo
                                            return(
                                                <tr key={key}>
                                                    <td>{n.marca}</td>
                                                    <td>{n.nombreArticulo}</td>
                                                    <td>{n.entregado}</td>
                                                    {commons.checkPermission(this.props.userData.permisos,['VerTotales', 'Administrador']) ?
                                                    <Fragment>
                                                        <td className="text-center">{n.precioUnitario}</td>
                                                        <td className="text-center">{n.entregado*n.precioUnitario}</td>
                                                    </Fragment>
                                                    :null}
                                                </tr>
                                            )
                                        
                                        })
                                    
                                        :null}
                                </tbody>
                            </Table>
                            
                        </div>
                }
            </div>

        )
    }
}

export default withRouter(VerRemito)
