import React, {Component} from 'react'
import commons from '../../common'
import { Table, Form, Col, Button,Modal} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { withRouter } from 'react-router-dom'


class Remito extends Component {

    state =  {
        permiso : true,
        codigoDeBarra : '',
        show : false,
        pedidoPendiente: [],
        header: null,
        light: 'transparent',
        resultados: [],
        listasDePrecios: [],
        listaDePrecios: '',
        cantidadEscaneada: 1
    }


    componentDidMount(){
        if(!commons.checkPermission(this.props.userData.permisos,['CrearRemito','Administrador'])){
            this.setState({permiso : false})
        } else {
            this.getPedidoPendiente()
            this.getListasDePrecios()
            this.nameInput.focus()
        }
    }

    getListasDePrecios = async () => {
        const body = {  
            "id": "getListasDePrecios",
            "idUsuario" : this.props.userData.idUsuario
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({listasDePrecios : response.listas})
        } 
    }

    clearLight = () => {
    this.setState({light: 'transparent' })
    }

    selectListaPrecio = (e) => {
        this.setState({listaPrecios: e.target.id})
    }

    delFn = (bc) => {
        console.log("delFn: "+bc)
        let {pedidoPendiente } = this.state
        pedidoPendiente.forEach(el=>{
            if(el.codigoDeBarra===bc){
                el.cargados = el.cargados?el.cargados = el.cargados-1: el.cargados
            }
        })
        this.setState({pedidoPendiente})
    
    }

    getPedidoPendiente = async () => {
        const body = { 
            id: 'getPedidoPendiente',
            idPedido : this.props.match.params.id
        }

        let response = await commons.doPost(this.props.token,body)

        if (response.result === 'ok') {
            this.setState({ pedidoPendiente: response.pendiente, header: response.header},()=>{
            this.setState({listaDePrecios: this.state.header.idLista})
            })
        } else {
            alert("Error ")
        }
    }

    handleChangeCodBarra = (e) =>{
        e.persist()
        if(e.target.value!==' ') {
            let {pedidoPendiente } = this.state
            let codigoDeBarra = e.target.value
            let found = false
            let {cantidadEscaneada} = this.state
            pedidoPendiente.forEach(el=>{
                if(el.codigoDeBarra===e.target.value){
                    el.cargados = el.cargados?el.cargados = el.cargados+parseInt(cantidadEscaneada): parseInt(cantidadEscaneada)
                    el.cargados = el.cargados > (parseInt(el.cantidad)-parseInt(el.entregado)) ? (parseInt(el.cantidad)-parseInt(el.entregado)) : el.cargados
                    codigoDeBarra = ''
                    found = true
                }
            })
            let light = found ? 'green' : 'red'
            this.setState({pedidoPendiente, codigoDeBarra: codigoDeBarra, light: light},()=>{
                setTimeout(()=>{ this.clearLight() },3000)
            })
        }
    }

    enviarNotificaciones = async (idRemito) => {
        const body = { accion: 'nuevoRemito', idRemito: idRemito }
        let response = await commons.doPost(this.props.token, body, 'ntf')

        if (response.result === 'ok') {
            let {resultados} = this.state
            resultados.push('Se enviaron las notificaciones')
            this.setState({ resultados})
        } else {
            alert("Error ")
        }
    }

    handleClose = () =>{
        this.setState({handleShow : false})
    }

    handleClickGenerarRemito = () => {
        this.setState({ show: true })
    }

    handleClose = () => {
        this.setState({ show: false })
    }

    handleGenerar = async () => {
        let total = 0
        
        this.state.pedidoPendiente.forEach(el=>{
            if(el.cargados) {
                total += parseInt(el.cargados)
            }
        })

        if(total>0){
            const body = { 
                id: 'generarRemito',
                header : this.state.header,
                pedidoPendiente: this.state.pedidoPendiente,
                idLista: this.state.listaDePrecios
            }

            let response = await commons.doPost(this.props.token,body)

            if (response.result === 'ok') {
                let { resultados } = this.state
                resultados.push("Remito creado ok...")
                this.setState({resultados, idRemito: response.idRemito})
                await this.enviarNotificaciones(response.idRemito)
                alert("Remito generado ok")
            } else {
                alert("Error ")
            }
            this.setState({ show: false })
        } else {
            alert("No se puede cargar remito si lo entregado es 0")
        }

    }
    
    onKD = (e) => {
      if(e.keyCode === 32){
          this.setState({codigoDeBarra: '',light:'transparent'})
      }
   }

    handleChangeCantidadEscaneada = (e) => {
        this.setState({[e.target.id]: e.target.value})
    }

    volver = () =>  {
    this.props.history.push('/listadoPedidos')
    }

    handleChangeListaDePrecios = (e) => {
        this.setState({listaDePrecios: e.target.value})
    }


    render(){
        return(
            <div className="mt-2 mb-2 text-center">
                <div className="text-primary">
                    <h5>Crear remito</h5>
                </div>

                {!this.state.permiso?


                <div className="text-center mt-4">
                    <small className="text-muted">No posee permisos para generar un remito</small>
                </div>
                        :
                            <div> 
                                {this.state.header?

                                <div className="container p-3 justify-content-around">
                                    <div className="row bg-light p-1">
                                        <div className="col">Pedido</div>
                                        <div className="col">Cliente</div>
                                        <div className="col">Fecha</div>
                                        <div className="col">Lista de precios</div>
                                    </div>
                                    <div className="row p-2">
                                        <div className="col">{this.state.header.idPedido}</div>
                                        <div className="col">{this.state.header.cliente}</div>
                                        <div className="col">{this.state.header.fecha.split(" ")[0]}</div>
                                        <div className="col">
                                        
                                        <Form.Control  as="select" name="listaDePrecios" value={this.state.listaDePrecios} onChange={this.handleChangeListaDePrecios}>
                                            <option>Seleccione...</option>
                                            {this.state.listasDePrecios.map(n => { return (<option key={n.idLista} value={n.idLista}>{n.nombreLista}</option>) })}
                                        </Form.Control>
                                        </div>
                                    </div>
                                </div>

                                :null}
                                <div className="m-2">
                                    <div className="container p-3 bg-light">
                                        <div className="row">
                                            <div className="col">Cantidad</div>
                                            <div className="col">Código</div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <input type="number" id="cantidadEscaneada" value={this.state.cantidadEscaneada} onChange={this.handleChangeCantidadEscaneada} />
                                            </div>
                                            <div className="col" style={{ background: this.state.light}}>
                                            <input type="text" id="codigoDeBarra" ref={(input) => { this.nameInput = input }} onKeyDown={this.onKD} value={this.state.codigoDeBarra} onChange={this.handleChangeCodBarra} />
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary btn-sm text-center m-2" onClick={this.volver}>Volver</button>
                                    <Table striped bordered hover size="sm" className="mt-4">
                                        <thead>
                                            <tr>
                                                <th>Marca</th>
                                                <th>Artículo</th>
                                                <th>Opci&oacute;n</th>
                                                <th>Pendientes</th>
                                                <th>Cargados</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.pedidoPendiente.map(n => { return itemsPendiente(n,this.delFn) })}
                                        </tbody>
                                    </Table>

                                </div> 

                                <div className="row justify-content-around mb-3 bg-light">
                                    <Col>
                                        {this.state.resultados.length>0?
                                                this.state.resultados.map(el=>{
                                                    return <div key={el}><small>{el}</small><br/></div>
                                                })
                                        :null}
                                    </Col>
                                </div>

                                <div className="row justify-content-around mb-3">

                                    <Form.Row>
                                        <div>
                                            <Col>
                                                <Button onClick={this.handleClickGenerarRemito} variant="secondary" size="sm" block>Generar Remito</Button>
                                            </Col>
                                        </div>
                                    </Form.Row>

                                </div>

                                <div>
                                    <Modal show={this.state.show}>

                                        <Modal.Header>
                                            <Modal.Title>Confirma?</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p>Pedido : {this.state.header?this.state.header.nombrePedido: null}</p>
                                            <p>Cliente : {this.state.header?this.state.header.cliente:null}</p>
                                            <p>Fecha Pedido : {this.state.header?this.state.header.fecha.split(" ")[0]:null}</p>
                                            <p>Fecha Remito : {getCurrentDate()}</p>
                                            <p>Generado por : {this.props.userData.usuario}</p>
                                            <Table striped bordered hover size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Marca</th>
                                                        <th>Artículo</th>
                                                        <th>Pendientes</th>
                                                        <th>Cargados</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.state.pedidoPendiente.map(n => { return resumenModal(n) })}
                                                </tbody>
                                            </Table>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleClose}>
                                                Cancelar
                                            </Button>
                                            <LinkContainer to="/listadoPedidos">
                                                <Button variant="primary" onClick={this.handleGenerar}>
                                                    Generar Remito
                                                </Button>
                                            </LinkContainer>
                                        </Modal.Footer>

                                    </Modal>
                                </div>
                            </div> 
                }
            </div>
        )
    }

}

const resumenModal = (n) => {
    return (
        <tr key={n.codigoDeBarra}>
            <td>{n.marca}</td>
            <td>{n.nombreArticulo}</td>
            <td>{n.cantidad-n.entregado}</td>
            <td>{n.cargados?n.cargados:0}</td>
        </tr>
    )
}

const itemsPendiente = (n,delFn) => {
    return (
        <tr key={n.codigoDeBarra}>
            <td>{n.marca}</td>
            <td>{n.nombreArticulo}</td>
            <td>{n.opcion}</td>
            <td>{n.cantidad-n.entregado}</td>
            <td>{n.cargados?n.cargados:0}</td>
            <td>{n.cargados && n.cargados>0? <button className="btn btn-danger btn-sm" onClick={()=>{delFn(n.codigoDeBarra)}}>-1</button>:null}</td>
        </tr>
    )
}


const getCurrentDate =()=>{

    let separator = '-'
    let newDate = new Date()
    let date = newDate.getDate()
    let month = newDate.getMonth() + 1
    let year = newDate.getFullYear()

    return(
        `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`
    ) 
}



export default withRouter(Remito)
