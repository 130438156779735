import React,{Component} from 'react'
import commons from '../../common'
import {Typeahead} from 'react-bootstrap-typeahead' 


class CuentaCorriente extends Component {

    state = {
        permiso: true,
        clientes : [],
        idRemito: '',
        cc: [],
        idCliente: null,
        idRemitoPago: '',
        pago: '',
        debe: 0,
        haber: 0,
        saldo: 0
    }

    setPago = (idRemito) => {
    this.setState({idRemitoPago: idRemito}) 
    }

    agregarPago = async () => {
        if(parseInt(this.state.pago)!==0){
            if(this.state.pago){
                const body = ({ id: 'agregarPago' , cliente: this.state.idCliente, pago: this.state.pago})
                let response = await commons.doPost(this.props.token, body)
                if (response.result === 'ok') {
                    alert('Pago registrado ok')
                    this.obtenerCuentaCorriente()
                } else {
                    alert("Error ")
                }
            } else {
                alert("Ingrese monto para el pago")
            }

        } else {
            alert("No se pueden cargar pagos en 0")
        }
    }

    getClientes = async () => {
        const body = ({ id: 'getClientes'})
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            this.setState({ clientes: response.cliente })
        } else {
            alert("Error ")
        }
    }

    obtenerCuentaCorriente = async () => {
        const body = ({ id: 'obtenerCuentaCorriente'})
        if(this.state.idRemito){
            body['idRemito'] = this.state.idRemito
        }
        if(this.state.idCliente){
            body['idCliente'] = this.state.idCliente[0].idCliente
        }
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            let debe = 0.0
            let haber = 0.0
            if(response.cc.length>0){
                response.cc.forEach(cc=>{
                    if(cc.idPago){ 
                    haber+=cc.pago
                    } else {
                        debe+=-1*(cc.totalRemito/(1+(cc.descuento/100)))
                    }
                })
            }
            this.setState({ cc: response.cc , debe: debe.toFixed(2), haber: haber.toFixed(2), saldo: (debe+haber).toFixed(2)})
        } else {
            alert("Error ")
        }
    }

    handleChangeCliente =(e)=>{
        this.setState({idCliente: e })
    }

    onChPago = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    componentDidMount(){
        if (commons.checkPermission(this.props.userData.permisos,['VerCuentaCorriente', 'Administrador'])) {
            this.getClientes()
        } else {
            this.setState({ permiso: false})
        }
    }

    render(){
        return (
            <div>
                <div className="mt-2 mb-2 text-center">
                    <h5 className="text-primary">Cuenta corriente</h5>
                </div>
                {this.state.permiso?null:<small className="text-danger text-center">No tiene permisos para utilizar esta sección</small>}
                <div>
                    <div className="container border border-info">
                        <div className="row p-1">
                            <div className="col bg-light">Cliente</div>
                            <div className="col">
                                <Typeahead onChange={this.handleChangeCliente} labelKey="nombre" id="nombreCliente" options={this.state.clientes} placeholder="Seleccione un cliente.." />
                            </div>
                        </div>
                        <div className="row p-1">
                            <div className="col text-center">
                                <button className="btn btn-info btn-sm" disabled={!this.state.permiso} onClick={this.obtenerCuentaCorriente}>Buscar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="m-2"/>

                <div className="container border border-info">
                    <div className="row p-2 bg-light text-center">
                        <div className="col">Total debe</div>
                        <div className="col">Total haber</div>
                        <div className="col">Saldo</div>
                    </div>
                    <div className="row p-2 text-center">
                        <div className="col"><small>{this.state.debe}</small></div>
                        <div className="col"><small>{this.state.haber}</small></div>
                        <div className="col"><small>{this.state.saldo}</small></div>
                    </div>
                </div>

                <div className="m-2"/>
                <div className="container border border-info">
                    <div className="row p-2 text-center bg-light">
                        <div className="col">Remito</div>
                        <div className="col">$</div>
                        <div className="col"></div>
                    </div>
                    <div className="row p-2 text-center">
                        <div className="col">{this.state.idRemitoPago}</div>
                        <div className="col"><input name="pago" type="text" value={this.state.pago} onChange={this.onChPago}/></div>
                        <div className="col"><button onClick={this.agregarPago} className="btn btn-sm btn-success">Agregar pago</button></div>
                    </div>
                </div>

                <div className="container p-2 mt-2">
                    <div className="row p-2 bg-light">
                        <div className="col"></div>
                        <div className="col">Cliente</div>
                        <div className="col">Remito</div>
                        <div className="col">Fecha remito</div>
                        <div className="col">Total</div>
                        <div className="col">Pago</div>
                        <div className="col">Fecha pago</div>
                    </div>
                    {this.state.cc.length>0?this.state.cc.map(r=>{
                        let key = r.fechaRemito ? r.fechaRemito : r.idPago
                        let debe = r.totalRemito? (r.totalRemito/(1+(r.descuento/100))).toFixed(2)*-1 : null
                        let fechaRemito = r.fechaRemito? r.fechaRemito.toString().split(" ")[0]: null
                        let pago = r.pago ? r.pago : null
                        let fechaPago = r.fechaPago? r.fechaPago: null
                        let botonPago = r.pago? null : <button onClick={()=>{this.setPago(r.idRemito)}} className="btn btn-sm btn-success"><i className="fa fa-dollar"/></button>
                        return (
                            <div className="row" key={key}>
                                <div className="col">{botonPago}</div>
                                <div className="col"><small>{r.cliente}</small></div>
                                <div className="col"><small>{r.idRemito}</small></div>
                                <div className="col"><small>{fechaRemito}</small></div>
                                <div className="col"><small>{debe}</small></div>
                                <div className="col"><small>{pago}</small></div>
                                <div className="col"><small>{fechaPago}</small></div>
                            </div>
                        )
                    })
                    :null}
                </div>

            </div>
        )
    }
}

export default CuentaCorriente
