import React, { Component,Fragment } from 'react'
import commons from '../../common'
import { Form } from 'react-bootstrap'

class ListaDePrecios extends Component {

    state = {
        permiso: false,
        listasDePrecios: [], //todas las creadas para el combo
        listaPrecios: [], //la seleccionada para ver (items)
        listaDePrecios: '', //la seleccionada (combo)
        listaDePreciosUsuario: '',
        listaDePreciosGestion: '',
        listaDePreciosRenombrar: '', //la que usa para agregar/quitar usuarios
        nuevoNombre: '',
        usuario: '', //el usuario para agregar/quitar de la lista
        usuarios: [],
        usuariosLista: [],
        precio: 0,
        nuevaLista: '', //el nombre nuevo,
        marcas: [],
        idMarca: ''
    }

    getMarcas = async () => {
        const body = { id: 'obtenerMarcas' }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ marcas: response.marcas })
        } else {
            alert("Error ")
        }
    }

    actualizarUsuarioLista = async (a) => {
        const body = {  
            "id": "actualizarUsuariosLista",
            "idUsuario" : this.props.userData.idUsuario,
            "accion" : a,
            "usuario": this.state.usuario,
            "idLista": this.state.listaDePreciosUsuario
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({usuariosLista : response.permisos},()=>{
                this.getListasDePrecios()
                alert("Cambio aplicado ok")
            })
        } else {
            alert("Error al obtener asignar.")
        }

    }

    componentDidMount(){
        if (commons.checkPermission(this.props.userData.permisos,['ListaDePrecios', 'Administrador'])) {
            this.getListasDePrecios()
            this.obtenerUsuarios()
            this.getMarcas()
            this.setState({permiso: true})
        }    
    }

    getUsuariosLista = async () => {
        const body = {  
            "id": "getUsuariosLista",
            "idUsuario" : this.props.userData.idUsuario,
            "idLista" : this.state.listaDePreciosUsuario
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({usuariosLista : response.permisos})
        } else {
            alert("Error al obtener usuarios por lista .")
        }

    }

    obtenerUsuarios = async () => {
        const body = {  
            "id": "obtenerUsuarios",
            "idUsuario" : this.props.userData.idUsuario
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({usuarios : response.usuarios})
        } else {
            alert("Error al obtener usuarios.")
        }

    }

    getListasDePrecios = async () => {
        const body = {  
            "id": "getListasDePrecios",
            "idUsuario" : this.props.userData.idUsuario
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({listasDePrecios : response.listas})
        } else {
            alert("Error al obtener la lista de precios.")
        }

    }

    getListaDePrecios = async () => {
        const body = {  
            "id": "getListaDePrecios",
            "idUsuario" : this.props.userData.idUsuario,
            "idLista" : this.state.listaDePreciosGestion
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({listaPrecios : response.listaDePrecios})
        } else {
            alert("Error al obtener la lista de precios.")
        }

    }

    onChNuevaLista = (e) =>{
        this.setState({ nuevaLista: e.target.value})
    }

    onChNuevoNombre = (e) =>{
        this.setState({ nuevoNombre: e.target.value})
    }

    cambiarPrecio = async (idArticulo,precio) => {
        const body = {  
            id: 'modificarPrecio',
            idUsuario : this.props.userData.idUsuario,
            idArticulo: idArticulo,
            precio: precio,
            idLista: this.state.listaDePreciosGestion
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({listaPrecios : response.listaDePrecios})
        } else {
            alert("Error al modificar precio")
        }

    }
    crearListaNueva = async () =>{
        const body = {  
            "id": "crearListaDePrecios",
            "idUsuario" : this.props.userData.idUsuario,
            "nuevaLista" : this.state.nuevaLista,
            "idMarca": this.state.idMarca
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.getListasDePrecios()
            alert("Lista creada ok!")
        } else {
            alert("Error al obtener la lista de precios.")
        }
    }

    borrarLista = async() => {
        const body = {  
            "id": "borrarListaDePrecios",
            "idUsuario" : this.props.userData.idUsuario,
            "idLista" : this.state.listaDePrecios
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.getListasDePrecios()
            alert("Lista eliminada")
        } else {
            alert(response.message)
        }
    }

    renombrarLista = async() => {
        const body = {  
            "id": "renombrarListaDePrecios",
            "idUsuario" : this.props.userData.idUsuario,
            "idLista" : this.state.listaDePreciosRenombrar,
            "nuevoNombre" : this.state.nuevoNombre
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.getListasDePrecios()
            alert("Se actualizo el nombre de la lista")
        } else {
            alert(response.message)
        }
    }

    onChPrecio = async (e) => {
        this.setState({precio: e.target.value})
    }

    handleSelectLPG = (e) => {
        this.setState({[e.target.name]: e.target.value},()=>{
            this.getUsuariosLista()
            this.getListaDePrecios()
        })
    }

    handleChangeMarca = (e) => {
    this.setState({[e.target.name]: e.target.value})
    }

    handleSelectLPU = (e) => {
        this.setState({[e.target.name]: e.target.value},()=>{
            this.getUsuariosLista()
        })
    }

    handleSelectLPR = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleSelectUsuario = (e) => {
        this.setState({usuario: e.target.value})
    }

    handleSelect = (e) => {
        this.setState({listaDePrecios: e.target.value})
    }

    render() {
        return (
            <div>
                <div className="mt-2 text-center"><h5 className="text-primary">Listas De Precios</h5></div>
                {!this.state.permiso?
                <div className="mt-4 text-center"><small className="text-muted">Su usuario no tiene permisos para ver la lista de precios</small></div>:null}


                <div className="m-2">
                    {commons.checkPermission(this.props.userData.permisos,['Administrador'])?
                    <Fragment>
                        <div className="m-3">
                            <div className="container bg-light border border-secondary">
                                <div className="row p-2">
                                    <div className="col text-center">
                                        <small>Asignar lista a usuario</small>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Form.Control as="select" name="listaDePreciosUsuario" required onChange={this.handleSelectLPU} value={this.state.listaDePreciosUsuario}>
                                            <option value="">Seleccione lista</option>
                                            {this.state.listasDePrecios.length>0?
                                                    this.state.listasDePrecios.map(lp=>{
                                                        return <option key={lp.idLista} value={lp.idLista}>{lp.nombreLista}</option>
                                                    })
                                            :null}
                                        </Form.Control>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="select" name="listaUsuarios" required onChange={this.handleSelectUsuario} value={this.state.usuario}>
                                            <option value="">Seleccione usuario</option>
                                            {this.state.usuarios.length>0?
                                                    this.state.usuarios.map(lp=>{
                                                        return <option key={lp.idUsuario} value={lp.idUsuario}>{lp.nombre}</option>
                                                    })
                                            :null}
                                        </Form.Control>
                                    </div>
                            </div>
                            <div className="row p-2">
                                    <div className="col col-sm-12 text-center">
                                        <button className="btn-sm btn-success" onClick={()=>{this.actualizarUsuarioLista('agregar')}}>Asignar usuario</button><button className="btn-sm btn-danger" onClick={()=>{this.actualizarUsuarioLista('quitar')}}>Quitar usuario</button></div>
                                        <div className="col">
                                            {this.state.usuariosLista.length>0?this.state.usuariosLista.map(ul=>{
                                            return <p key={ul.idUsuario}><small>{ul.nombreUsuario}</small></p>}):null}
                                        </div>
                                    </div>
                                </div>        
                            </div>


                            <div className="m-3">
                                <div className="container bg-light border border-secondary">
                                    <div className="row">
                                        <div className="col text-center">
                                            <small className="p-2">Alta - Baja - Modificación</small>
                                        </div>
                                    </div>
                                    <div className="row p-1">
                                        <div className="col col-sm-3">
                                            <input type="text" placeholder="Nombre lista nueva" className="form-control" id="nuevaLista" onChange={this.onChNuevaLista} />
                                        </div>
                                        <div className="col col-sm-3">
                                           <Form.Control  as="select" name="idMarca" value={this.state.idMarca} onChange={this.handleChangeMarca}>
                                                <option>Seleccione marca...</option>
                                                {this.state.marcas.map(n => { return (<option key={n.idMarca} value={n.idMarca}>{n.nombre}</option>) })}
                                            </Form.Control>
                                        </div>
                                        <div className="col col-sm-2">
                                            <button className="mt-1 btn-sm btn-primary" onClick={this.crearListaNueva}>Crear lista</button></div>
                                        </div>
                                    <div className="row p-1 bg-light">
                                        <div className="col text-center">
                                            <Form.Control as="select" name="listaDePrecios" required onChange={this.handleSelect} value={this.state.listaDePrecios}>
                                                <option value="">Seleccione lista</option>
                                                {this.state.listasDePrecios.length>0?
                                                        this.state.listasDePrecios.map(lp=>{
                                                            return <option key={lp.idLista} value={lp.idLista}>{lp.nombreLista}</option>
                                                        })
                                                :null}
                                            </Form.Control>
                                        </div>
                                        <div className="col"><button className="mt-1 btn-sm btn-danger" onClick={this.borrarLista}>Borrar lista</button></div>
                                    </div>
                                    <div className="row bg-light p-1">
                                    <div className="col col-sm-3">
                                        <Form.Control as="select" name="listaDePreciosRenombrar" required onChange={this.handleSelectLPR} value={this.state.listaDePreciosRenombrar}>
                                            <option value="">Seleccione lista</option>
                                            {this.state.listasDePrecios.length>0?
                                                    this.state.listasDePrecios.map(lp=>{
                                                        return <option key={lp.idLista} value={lp.idLista}>{lp.nombreLista}</option>
                                                    })
                                            :null}
                                        </Form.Control>
                                    </div>
                                        <div className="col col-sm-3"><input type="text" placeHolder="Nuevo nombre" className="form-control" id="renombrar" onChange={this.onChNuevoNombre} /></div>
                                        <div className="col"><button className="mt-1 btn-sm btn-success" onClick={this.renombrarLista}>Renombrar lista</button></div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                            :null} 


                                <div className="container border border-secondary">
                                    <div className="row">
                                        <div className="col text-center"><small className="p-2">Gestión de precios</small></div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                        <Form.Control as="select" name="listaDePreciosGestion" required onChange={this.handleSelectLPG} value={this.state.listaDePreciosGestion}>
                                            <option value="">Seleccione lista</option>
                                            {this.state.listasDePrecios.length>0?
                                                    this.state.listasDePrecios.map(lp=>{
                                                        return <option key={lp.idLista} value={lp.idLista}>{lp.nombreLista}</option>
                                                    })
                                            :null}
                                        </Form.Control>
                                        </div>
                                    </div>
                                    <div className="row bg-light mt-2">
                                        <div className="col">Artículo</div>
                                        <div className="col">Marca</div>
                                        <div className="col">Precio actual</div>
                                        <div className="col">Nuevo precio</div>
                                        <div className="col"></div>
                                    </div>

                                    {this.state.listaPrecios?this.state.listaPrecios.map(n => {
                                        let precio = n.precio ? n.precio: 0
                                        return (
                                            <div className="row border border-1 p-1" key={n.idArticulo}>
                                                <div className="col">{n.nombreArticulo}</div>
                                                <div className="col">{n.marca}</div>
                                                <div className="col">$ {precio}</div>
                                                <div className="col">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="text" className="form-control" id="nuevoPrecio" onChange={this.onChPrecio} />
                                                    </div>
                                                </div>
                                                <div className="col"><button className="btn btn-primary btn-sm mt-1" onClick={()=>{this.cambiarPrecio(n.idArticulo,this.state.precio)}}>Cambiar</button></div>
                                            </div>
                                        )
                                    }):null}

                                </div>

                            </div>
                        </div>
        )
    }
}

export default ListaDePrecios
