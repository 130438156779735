import React, {Component, Fragment}  from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Form, Row, Col , Button, Table} from 'react-bootstrap'
import commons from '../../common'
import {Typeahead} from 'react-bootstrap-typeahead' 
import { withRouter } from "react-router"


const itemsArticulo = (n,removeFn) => {
    return (
        <tr key={n.idArticulo}>
            <td>{n.nombreArticulo}</td>
            <td>{n.cant}</td>
            <td><button className="btn btn-primary btn-sm" onClick={()=>{removeFn(n.idArticulo)}}>Quitar</button></td>
        </tr>
    )
}


class Pedido extends Component{

    state = {
        permiso: true,
        volver : false,
        aceptar : false,
        botonesControl: false,
        finalizar: true,
        idArticulo : '',
        nombreArticulo : '',
        idMarca : '',
        articulos : [],
        arrayArticulos: [],
        clientes : [],
        marcas : [],
        idPedido : '0',
        opcion: 'pack',
        cantidad : '',
        idCliente : '',
        resultados: [],
        listasDePrecios: [],
        listaDePrecios: ''
    }


    handleClickFinalizar = () => {
        this.props.history.push("/")
    }


    handleChangeOpcion = (e) => {
        this.setState({[e.target.id]: e.target.value})
    }

    handleClickVolver = () =>{
        this.setState({volver : true})
    }

    handleClickAceptar= async ()=>{
        this.setState({aceptar : true})
        await(this.nuevoPedido())
    }

    removeFn = (j) => {
        let {arrayArticulos } = this.state
        for(let i=0;i<arrayArticulos.length;i++){
            if(arrayArticulos[i].idArticulo === j){
                arrayArticulos.splice(i,1)
                break
            }
        }
        this.setState({arrayArticulos})
    }

    getListasDePrecios = async () => {
        const body = {  
            "id": "getListasDePrecios",
            "idUsuario" : this.props.userData.idUsuario
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({listasDePrecios : response.listas})
        } 
    }

    handleClickAgregar = () =>{
        let { arrayArticulos } = this.state
        let exists = false
        arrayArticulos.forEach(el=>{
            if(el.idArticulo===this.state.idArticulo){
                el.cant = this.state.cantidad
                exists = true
            }
        })
        if(!exists && parseInt(this.state.cantidad)>0){
            arrayArticulos.push({idArticulo: this.state.idArticulo, nombreArticulo: this.state.nombreArticulo, cant: this.state.cantidad, opcion: this.state.opcion})
        }

        this.setState({arrayArticulos})
    }

    handleChangeMarca =(e)=>{
        e.persist()
        this.setState({ idMarca: e.target.value },()=>{
            this.getArticulos(this.state.idMarca)
        })
    }

    handleChangeArticulo =(e)=>{
        e.persist()
        this.state.articulos.forEach(el=>{
            if(el.idArticulo===parseInt(e.target.value)){
                this.setState({ idArticulo: e.target.value, nombreArticulo: el.nombreArticulo })
            }
        })
    }

    handleChangeCliente =(e)=>{
        let cl
        e.forEach( e=>{
            cl = e.idCliente
        })
        this.setState({ idCliente: cl })
        this.state.clientes.forEach(_a=>{
            if(_a.idCliente===parseInt(cl)){
                this.setState({
                    idCliente : _a.idCliente
                })
            }
        })
    }

    handleChangeCantidad =(e)=>{
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount(){
        if (commons.checkPermission(this.props.userData.permisos,['CrearPedido', 'Administrador'])) {
            this.getMarcas()
            this.getClientes()
            this.getListasDePrecios()
        } else {
            this.setState({ permiso: false})
        }
    }


    getMarcas = async () => {
        const body = { id: 'obtenerMarcas' }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ marcas: response.marcas })
        } else {
            alert("Error ")
        }
    }
    getArticulos = async (i) => {
        const body = { id: 'listarArticulos', idMarca: i }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ articulos: response.articulo })
        } else {
            alert("Error ")
        }
    }

    getClientes = async () => {
        const body = { id: 'getClientes' }
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            this.setState({ clientes: response.cliente })
        } else {
            alert("Error ")
        }
    }

    nuevoPedido = async () => {
        const body = { 
            id: 'nuevoPedido',
            idCliente : this.state.idCliente,
            idUsuario : this.props.userData.idUsuario,
            arrayArticulos : this.state.arrayArticulos,
            idLista: this.state.listaDePrecios
        }
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            let { resultados } = this.state
            resultados.push('Pedido creado')
            resultados.push('Aguarde .. enviando notificaciones')
            this.setState({ idPedido: response.idPedido, resultados, botonesControl: true })
            this.enviarNotificaciones(response.idPedido)
        }
    }
    
    enviarNotificaciones = async (idPedido) => {
        //let cliente = ''
        //this.state.clientes.forEach(ct=>{
        //    if(ct.idCliente === this.state.idCliente) 
        //        cliente = ct.nombre
        //})

        const body = { accion: 'nuevoPedido', idPedido: idPedido }
        let response = await commons.doPost(this.props.token, body, 'ntf')

        if (response.result === 'ok') {
            let {resultados} = this.state
            resultados.push('Se enviaron las notificaciones')
            this.setState({ resultados, finalizar: false})
        } else {
            alert("Error ")
        }
    }

    handleChangeListaDePrecios = (e) => {
        this.setState({listaDePrecios: e.target.value})
    }

    render(){
        let { arrayArticulos,clientes } = this.state
        return(

            <div className="mt-2 mb-2 text-center">
                <div className="text-primary">
                    <h5>Generar pedido</h5>
                </div>
                <div>
                    <Form.Group as={Row} controlId="idMarca">
                        <Form.Label column md={2}>Marca</Form.Label>
                        <Col md={4}>
                            <Form.Control  as="select" name="idMarca" value={this.state.idMarca} onChange={this.handleChangeMarca}>
                                <option>Seleccione...</option>
                                {this.state.marcas.map(n => { return (<option key={n.idMarca} value={n.idMarca}>{n.nombre}</option>) })}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="idCliente">
                        <Form.Label column md={2}>Cliente</Form.Label>
                        <Col md={4}>
                            <Typeahead
                                onChange={this.handleChangeCliente}
                                labelKey="nombre"
                                id="nombreCliente"
                                options={clientes}
                                placeholder="Seleccione un cliente.."
                            />
                        </Col>
                    </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column md={2}>Lista de Precios</Form.Label>
                            <Col md={4}>
                            <Form.Control  as="select" name="listaDePrecios" value={this.state.listaDePrecios} onChange={this.handleChangeListaDePrecios}>
                                <option>Seleccione...</option>
                                {this.state.listasDePrecios.map(n => { return (<option key={n.idLista} value={n.idLista}>{n.nombreLista}</option>) })}
                            </Form.Control>
                            </Col>

                        </Form.Group >
                        <Form.Group as={Row}>
                            <Form.Label column md={2}>Artículo</Form.Label>
                            <Col md={4}>
                            <Form.Control  as="select" name="idArticulo" value={this.state.idArticulo} onChange={this.handleChangeArticulo}>
                                <option>Seleccione...</option>
                                {this.state.articulos.map(n => { return (<option key={n.idArticulo} value={n.idArticulo}>{n.nombreArticulo}</option>) })}
                            </Form.Control>
                            </Col>

                        </Form.Group >

                        <Form.Group as={Row}>
                                <Form.Label column md={2}>Cantidad</Form.Label>
                            <Col md={4}>
                                <Form.Control  type="number" name="cantidad" value={this.state.cantidad} onChange={this.handleChangeCantidad} />
                            </Col>
                        </Form.Group>
                         <Form.Group as={Row}>
                          <Form.Label column md={2}>&nbsp;</Form.Label>
                            <Col md={4}>
                                <div className="form-check-inline">
                                <input className="form-check-input" type="radio" name="opcion" id="opcion" value="pack" checked onChange={this.handleChangeOpcion}/>
                                    <label className="form-check-label" htmlFor="opcion">Pack</label>
                                </div>
                                <div className="form-check-inline">
                                <input className="form-check-input" type="radio" name="opcion" id="opcion" value="individual" onChange={this.handleChangeOpcion}/>
                                    <label className="form-check-label" htmlFor="opcion">Individual</label>
                                </div>
                            </Col>
                        </Form.Group>


                    <div>
                        <div className="row justify-content-around mb-3">
                            <Form.Row>
                                <div>
                                    <Col>
                                        <Button onClick={this.handleClickAgregar} variant="secondary" size="sm" block disabled={this.state.botonesControl}>Agregar</Button>
                                    </Col>
                                </div>
                            </Form.Row>
                        </div>
                    </div>
                </div>

                <div>

                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Artículo</th>
                                <th>Cantidad</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {arrayArticulos.map(n => { return itemsArticulo(n,this.removeFn) })}
                        </tbody>
                    </Table>

                </div>

                <div className="row justify-content-around">
                    <Form.Row>
                        <Col>
                            { this.state.permiso ?
                            <Fragment>
                                {this.state.resultados.length>0?
                                        this.state.resultados.map(el=>{
                                            return <div key={el}><small>{el}</small><br/></div>
                                        })
                                :null}
                                <Button onClick={this.handleClickAceptar} variant="secondary" size="sm" block disabled={this.state.botonesControl}>Generar Pedido</Button>
                                <Button onClick={this.handleClickFinalizar} variant="info" size="sm" block disabled={this.state.finalizar}>Finalizar</Button>
                            </Fragment>
                                    : <p>Su usuario tiene permisos para agregar artículos</p>
                            }
                        </Col>
                    </Form.Row>
                </div>
            </div>
        )
    }
}


export default withRouter(Pedido)
