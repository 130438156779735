import React, {Component} from 'react'
import commons from '../../common'
import { Table } from 'react-bootstrap'

class ListadoUsuarios extends Component {

    state = {
        permiso : true,
        usuarios: []
    }

    obtenerUsuarios = async () => {
        const body = { id: 'obtenerUsuarios', idUsuario : this.props.userData.idUsuario }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ usuarios: response.usuarios })
        } else {
            alert("Error ")
        }
    }

    componentDidMount(){
        //chequear permisos
        if (commons.checkPermission(this.props.userData.permisos,['Administrador'])) {
            this.obtenerUsuarios()
        } else {
            this.setState({ permiso: false})
        }
    }

    render(){
        return (
            <div className="mt-2">
                <h5 className="text-primary text-center">
                    Listado de usuarios
                </h5>
                {!this.state.permiso?
                <h6 className="text-center text-danger">Su usuario no tiene permisos para ejecutar esta acción</h6>
                :null}
                <Table striped bordered hover size="sm" className="m-2">
                    <thead>
                        <tr>
                            <th>Usuario</th>
                            <th>Nombre</th>
                            <th>Habilitado</th>
                            <th>Comisión</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.usuarios.map(n => {
                            return (
                                <tr key={n.idUsuario}>
                                    <td>{n.usuario}</td>
                                    <td>{n.nombre}</td>
                                    <td>{n.habilitado?"Si":"No"}</td>
                                    <td>{n.comision}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }

}

export default ListadoUsuarios
