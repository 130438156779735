import React,{Component} from 'react'
import commons from '../../common'
import { withRouter } from 'react-router-dom'
import { Form, Table, Modal } from 'react-bootstrap'
import {Typeahead} from 'react-bootstrap-typeahead' 

class ListadoRemitos extends Component{

    state = {
        remitos: [],
        nombrePedido: '',
        permiso: true,
        idCliente: '',
        clientes: [],
        swow: true,
        nombreRemito: '',
        fechaRemito: '',
        idRemito: '',
        listasDePrecios: [],
        listaDePrecios: '',
        referenciaExterna: '',
        modalBanner: ''
    }

    componentDidMount(){
        if(!commons.checkPermission(this.props.userData.permisos,['ListarRemitos','Administrador'])){
            this.setState({permiso : false})
        } else {
            if(this.props.match.params.id)
                this.getRemitos()
            this.getClientes()
            this.getListasDePrecios()
        }
    }

    refresh = () => {
        this.getRemitos() 
    }

    handleGenerar = () => {
        this.setState({modalBanner: 'Aguarde ...'},()=>{
            this.enviarVenta()
        }) 
    }

    enviarVenta = async () => {
        const body = {  
            "id": "generarVenta",
            "idUsuario" : this.props.userData.idUsuario,
            "idRemito" : this.state.idRemito,
            "idListaPrecios" : this.state.listaDePrecios,
            "referenciaExterna" : this.state.referenciaExterna
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({
                modalBanner: '',
                show: false
            })
        } 
    }

    onChRefExt = (e) => {
    this.setState({[e.target.name]: e.target.value})
    }

    getListasDePrecios = async () => {
        const body = {  
            "id": "getListasDePrecios",
            "idUsuario" : this.props.userData.idUsuario
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({listasDePrecios : response.listas})
        } 
    }

    handleClose = () => {
     this.setState({show: false})
    }

    handleChangeListaDePrecios = (e) => {
        this.setState({[e.target.name]: e.target.value}) 
    }

    generarVenta = (nombreRemito,fechaRemito,idRemito) => {
        this.setState({show:true, nombreRemito: nombreRemito, fechaRemito: fechaRemito, idRemito: idRemito})
    }
    
    handleChangeCliente =(e)=>{
        let cl
        e.forEach( e=>{
            cl = e.idCliente
        })
        this.setState({ idCliente: cl })
        this.state.clientes.forEach(_a=>{
            if(_a.idCliente===parseInt(cl)){
                this.setState({
                    idCliente : _a.idCliente,
                    nombreCliente: _a.nombre
                })
            }
        })
    }

    getClientes = async () => {
        const body = { id: 'getClientes' }
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            this.setState({ clientes: response.cliente })
        } else {
            alert("Error ")
        }
    }
    getRemitos = async () => {
            const body = { 
                id: 'listarRemitos',
            }
            if(this.props.match.params.id){
                body['idPedido'] = this.props.match.params.id 
            }
            if(this.state.idCliente){
                body['idCliente'] = this.state.idCliente
            }

            let response = await commons.doPost(this.props.token,body)

            if (response.result === 'ok') {
                let nombrePedido = response.remitos.length>0? response.remitos[0].nombrePedido: ''
                this.setState({ remitos: response.remitos, nombrePedido: nombrePedido })
            } else {
                alert("Error ")
            }
        }


    handleClickVer = (id) =>{
        this.props.history.push("/verRemito/"+id)
    }

    handleClickPDF = async (id) =>{
        window.open("/pdf?remito="+id+"&reporte=remito","_blank")
    }

    volver = () => {
        this.props.history.push("/listadoPedidos")
    }

    render(){
        return (
            <div className="mt-2 mb-2 text-center">
                <div className="text-primary">
                    <h5>Listado remitos {this.state.idPedido? 'para el pedido '+this.state.idPedido: this.state.idCliente?'para el cliente '+this.state.nombreCliente: null}</h5>
                </div>

                {!this.state.permiso?


                <div className="text-center mt-4">
                    <small className="text-muted">No posee permisos para listar remitos</small>
                </div>
                        :
                        <div>
                            {this.props.match.params.id?
                            <button className="btn btn-sm btn-primary m-2" onClick={this.volver}>Volver</button>
                            :
                                    <div className="container border border-info">
                                        <div className="row p-2 text-center">
                                            <div className="col mt-1 bg-light">Cliente</div>
                                            <div className="col">
                                                <Typeahead
                                                    onChange={this.handleChangeCliente}
                                                    labelKey="nombre"
                                                    id="nombreCliente"
                                                    options={this.state.clientes}
                                                    placeholder="Seleccione un cliente.."
                                                />
                                            </div>
                                            <div className="col"><button className="btn btn-sm btn-primary" onClick={this.getRemitos}><i className="fa fa-search" aria-hidden="true"></i></button></div>
                                        </div>
                                    </div>
                                }

                                <div>
                                    <Modal show={this.state.show}>
                                        <Modal.Header>
                                            <Modal.Title>Crear venta</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p>Remito : {this.state.nombreRemito}</p>
                                            <p>Cliente : {this.state.nombreCliente}</p>
                                            <p>Fecha remito : {this.state.fechaRemito}</p>
                                            <div className="container">
                                                <div className="row mt-2">
                                                    <div className="col bg-light">Lista de precios</div>
                                                    <div className="col">
                                                        <Form.Control  as="select" name="listaDePrecios" value={this.state.listaDePrecios} onChange={this.handleChangeListaDePrecios}>
                                                            <option>Seleccione...</option>
                                                            {this.state.listasDePrecios.map(n => { return (<option key={n.idLista} value={n.idLista}>{n.nombreLista}</option>) })}
                                                        </Form.Control>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col bg-light">Referencia</div>
                                                    <div className="col"><input type="text" name="referenciaExterna" value={this.state.referenciaExterna} onChange={this.onChRefExt}/></div>
                                                </div>
                                            </div>
                                            <h5>{this.state.modalBanner}</h5>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-sm btn-secondary" onClick={this.handleClose}>Cancelar</button>
                                            <button className="btn btn-sm btn-primary" onClick={this.handleGenerar}>Crear venta</button>
                                        </Modal.Footer>

                                    </Modal>
                                </div>

                                <div className="text-center m-2"><button className="btn btn-sm btn-primary" disabled={!this.state.idCliente} onClick={this.refresh}><i className="fa fa-refresh" aria-hidden="true"/></button></div>

                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Remito</th>
                                        <th>Cliente</th>
                                        <th>Fecha</th>
                                        {commons.checkPermission(this.props.userData.permisos,['VerTotales', 'Administrador']) ?
                                        <th className="text-center">Total $</th>
                                        :null}
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                    this.state.remitos?
                                        this.state.remitos.map(n=>{
                                            return(
                                                <tr key={n.idRemito}>
                                                    <td>{n.idRemito}</td>
                                                    <td>{n.nombreCliente}</td>
                                                    <td>{n.fechaRemito.split(' ')[0]}</td>
                                                    {commons.checkPermission(this.props.userData.permisos,['VerTotales', 'Administrador']) ?
                                                    <td className="text-center">{n.descuento>0?(n.importeTotal/(1+(n.descuento/100))).toFixed(2):n.importeTotal}</td>
                                                    :null}
                                                    <td>
                                                        <button className="btn btn-info btn-sm" disabled={!this.state.permiso} onClick={e=>{this.handleClickVer(n.idRemito)}}>Ver</button>
                                                        <button className="btn btn-info btn-sm" disabled={!this.state.permiso} onClick={e=>{this.handleClickPDF(n.idRemito)}}>PDF</button>
                                                        {commons.checkPermission(this.props.userData.permisos,['Venta','Administrador']) && n.idVenta===null?
                                                                <button className="btn btn-warning btn-sm" disabled={!this.state.permiso} onClick={()=>this.generarVenta(n.nombreRemito,n.fechaRemito.split(' ')[0],n.idRemito)}><i className="fa fa-handshake-o" aria-hidden="true"/></button>:null}

                                                </td>
                                                </tr>
                                            )
                                        
                                        })
                                    
                                        :null}
                                </tbody>
                            </Table>
                        </div>

                }
            </div>
        )}

}

export default withRouter(ListadoRemitos)
