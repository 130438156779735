import React, { Component } from 'react'
import { Form, Col, Button, Row} from 'react-bootstrap'
import commons from '../../common'

const optionsCliente = (n) => {
    return (
        <option key={n.idCliente} value={n.idCliente}>{n.nombre}</option>
    )
}

class ModificarCliente extends Component{

    state = {
        permiso: true,
        idCliente : '',
        nombre : '',
        email : '',
        telefono : '',
        cuit : '',
        descripcion : '',
        vendedor: '',
        clientes : [],
        usuarios: []
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        if (!commons.checkPermission(this.props.userData.permisos,['Administrador'])) {
            this.setState({ permiso: false})
        } else {
            this.getClientes()
            this.obtenerUsuarios()
        }
    }

    obtenerUsuarios = async () => {
        const body = { id: 'obtenerUsuarios', idUsuario : this.props.userData.idUsuario }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ usuarios: response.usuarios })
        } else {
            alert("Error ")
        }
    }

    handleChangeCliente =(e)=>{
        this.setState({ [e.target.name]: e.target.value })
        this.state.clientes.forEach(_a=>{
            if(_a.idCliente===parseInt(e.target.value)){
                this.setState({
                    idCliente : _a.idCliente,
                    nombre : _a.nombre,
                    email : _a.email,
                    telefono : _a.telefono,
                    cuit : _a.cuit,
                    descripcion : _a.descripcion,
                    vendedor: _a.vendedor
                })
            }
        })
    }

    getClientes = async () => {
        const body = ({ id: 'getClientes' })
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            this.setState({ clientes: response.cliente })
        } else {
            alert("Error ")
        }
    }

    handleClickModificar = async() =>{
        const body = { 
                id: 'modificarCliente',
                idCliente : this.state.idCliente,
                nombre : this.state.nombre,
                email: this.state.email,
                telefono: this.state.telefono,
                cuit : this.state.cuit, 
            descripcion : this.state.descripcion,
            vendedor: this.state.vendedor
            }
        let response = await commons.doPost(this.props.token, body)

        if(response.result === 'ok'){
            alert("Cliente modificado correctamente")
            this.setState({ articuloGuardado: true })
        }else{
            alert("Error : No se ha podido modificar el cliente seleccionado")
        }
    }


    render(){

        let clientes = this.state.clientes

        return(
            <div>
                <div className="text-primary mt-3 mb-3 text-center">
                    <h5>Modificar cLiente</h5>
                </div>


                <div className="m-2">
                    <Form>
                        <Form.Group as={Row} controlId="idCliente">
                            <Form.Label column md={2}>
                                Cliente
                            </Form.Label>
                            <Col>
                                <Form.Control as="select" name="idCliente" value={this.state.idCliente} onChange={this.handleChangeCliente} >
                                    <option>Seleccione...</option>
                                    {clientes.map(n => { return optionsCliente(n) })}
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column md={2}>Nombre</Form.Label>
                            <Col>
                                <Form.Control name="nombre" value = {this.state.nombre} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column md={2}>Email</Form.Label>
                            <Col>
                                <Form.Control  name="email" value = {this.state.email} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column md={2}>Teléfono </Form.Label>
                            <Col>
                                <Form.Control  name="telefono" value = {this.state.telefono} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column md={2}>CUIT</Form.Label>
                            <Col>
                                <Form.Control name="cuit" value = {this.state.cuit} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} >
                            <Form.Label column md={2}>Descripción</Form.Label>
                            <Col>
                                <Form.Control  name="descripcion" value = {this.state.descripcion} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} >
                            <Form.Label column md={2}>Vendedor</Form.Label>
                            <Col>
                            <Form.Control as="select" name="vendedor" onChange={this.handleChange} value={this.state.vendedor}>
                                <option>Seleccione</option>
                                {
                                    this.state.usuarios.map(n=>{
                                        return(<option key={n.idUsuario} value={n.idUsuario}>{n.usuario}</option>)
                                    })} 
                                    </Form.Control>
                                </Col>
                                </Form.Group>

                        <div className="row justify-content-around">
                            <Form.Row>
                                <div>
                                    <Col>
                                        {this.state.permiso?
                                            <Button onClick={this.handleClickModificar} variant="secondary" size="sm" block>
                                                Modificar
                                            </Button>:<p>No posee permisos para realizar esta acción</p>
                                        }   
                                    </Col>
                                </div>
                            </Form.Row>
                        </div>

                    </Form>
                </div>
            </div>
        )
    }
}

export default ModificarCliente
