//const url = 'http://localhost:8084/'
const url = 'https://gestion.sayka.com.ar/sk2'


const checkPermission = (pList, search) => {

    for (let i = 0; i < pList.length; i++) {
        if (pList.indexOf(search[i]) > -1) {
            return true
        }
    }
    return false

}

async function doPost(token, body, path) {
    try {
        const reqURL = path?url+"/"+path:url
        //console.log("Request using: "+reqURL)
        const req = await (await fetch(reqURL, {
            method: 'post',
            credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json', 'x-sk2-token': token },
            body: JSON.stringify(body)
        })).json()
        //console.log(req)

        if (req.result) {
            if (req.result === 'error') {
                let msg = "error: " + req.message
                console.log(msg)
            }
            return req
        } else {
            alert("No RoR")
        }
    } catch (error) {
        console.log(error)
        alert('Network error')
    }
    return null
}

async function doGet(token, body) {
    try {
        const req = await (await fetch(url, {
            method: 'get',
            headers: { 'Content-Type': 'application/json', 'x-sk-token': token },
            body: JSON.stringify(body)
        })).json()

        if (req.result) {
            if (req.result === 'error') {
                let msg = "error: " + req.message
                console.log(msg)
            } else {
                return req
            }
        } else {
            alert("No RoR")
        }
    } catch (error) {
        console.log(error)
        alert('Network error')
    }
    return null
}

function toHHmmss(input) {

    let decimal = input | 0;
    let fractional = input - decimal;

    let minutos = (Math.round((60 * fractional) * 100) / 100) | 0;

    return decimal + ":" + ('0' + minutos).slice(-2)

}

function fixDate(input) {
    let d = new Date(input)

    return ('0' + d.getDate()).slice(-2) + '/'
        + ('0' + (d.getMonth() + 1)).slice(-2) + '/'
        + d.getFullYear();
}

export default { doPost, toHHmmss, fixDate, doGet, checkPermission }
