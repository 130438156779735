import React, { Component } from 'react'
import commons from '../../common'
import { LinkContainer } from 'react-router-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Form, Col,  Row } from 'react-bootstrap'

class PendientesPorCliente extends Component {

    state = {
        permiso: false,
        clientes: [],
        pendientes: [],
        idcliente: '',
        nombreArticulo: ''
    }

    handleChangeArticulo = (e) => {
        this.setState({nombreArticulo: e.target.value})

    }

    handleChangeCliente = (e) => {
        let cl
        e.forEach(e => {
            cl = e.idCliente
        })
        this.setState({ idCliente: cl })
        this.state.clientes.forEach(_a => {
            if (_a.idCliente === parseInt(cl)) {
                this.setState({
                    idCliente: _a.idCliente
                })
            }
        })
    }

    refrescar = async () => {
        this.obtenerPendientesPorCliente()
    }

    getClientes = async () => {
        const body = { id: 'getClientes' }
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            this.setState({ clientes: response.cliente })
        } else {
            alert("Error ")
        }
    }

    componentDidMount() {
        if (commons.checkPermission(this.props.userData.permisos, ['PendientesPorCliente', 'Administrador'])) {
            this.setState({ permiso: true })
            this.getClientes()
            this.obtenerPendientesPorCliente()
        } else {
            this.setState({ permiso: false })
        }
    }

    obtenerPendientesPorCliente = async () => {
        const body = {
            id: 'pendientesPorCliente',
            idUsuario: this.props.userData.idUsuario,
            idCliente: this.state.idCliente ? this.state.idCliente : undefined
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ pendientes: response.pendientes })
        } else {
            alert("Error al modificar precio")
        }
    }

    render() {
        return (
            <div>
                <div className="mt-2 text-center"><h5 className="text-primary">Pendientes por cliente</h5></div>
                {!this.state.permiso ?
                    <div className="mt-4 text-center"><small className="text-muted">Su usuario no tiene permisos para ver esta sección</small></div> : null}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col text-center">
                            <Typeahead
                                onChange={this.handleChangeCliente}
                                labelKey="nombre"
                                id="nombreCliente"
                                options={this.state.clientes}
                                placeholder="Seleccione un cliente.."
                            />
                        </div>
                        <div className="col text-center">
                        <Form.Group as={Row} controlId="nombreArticulo">
                            <Form.Label column sm="2">
                                Artículo
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="text" required value={this.state.nombreArticulo} onChange={this.handleChangeArticulo} />
                            </Col>
                        </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <button className="btn btn-info btn-sm text-center m-2" onClick={this.refrescar}>Buscar</button>
                        </div>
                    </div>
                    <div className="row bg-light p-2 text-center">
                        <div className="col col-sm-1">Pedido</div>
                        <div className="col col-sm-1">Artículo</div>
                        <div className="col">Descripci&oacute;n</div>
                        <div className="col">Cliente</div>
                        <div claet sName="col col-sm-1">Pendiente</div>
                        <div className="col col-sm-1">Opci&oacute;n</div>
                        <div className="col col-sm-1">D&iacute;as</div>
                    </div>
                    {this.state.pendientes.length > 0 ?
                        this.state.pendientes.map(n => {
                            if((this.state.nombreArticulo && n.nombreArticulo === this.state.nombreArticulo) || !this.state.nombreArticulo){
                                let _diff = n.diff / 86400
                                return (
                                    <div className="row text-center" key={n.rand}>
                                        <div className="col col-sm-1"><LinkContainer to={"/verPedido/" + n.idPedido}><button className="btn btn-info btn-sm">{n.idPedido}</button></LinkContainer></div>
                                        <div className="col col-sm-1"><small>{n.nombreArticulo}</small></div>
                                        <div className="col"><small>{n.descripcion}</small></div>
                                        <div className="col"><small>{n.nombreCliente}</small></div>
                                        <div className="col col-sm-1"><small>{n.pendientes}</small></div>
                                        <div className="col col-sm-1"><small>{n.opcion}</small></div>
                                        <div className="col col-sm-1"><small>{parseInt(_diff)}</small></div>
                                    </div>
                                )
                            }
                                else return ''
                        })
                        : null}
                </div>


            </div>
        )
    }
}

export default PendientesPorCliente
