import React, { Component, Fragment } from 'react'
import { Table, Form } from 'react-bootstrap'
import commons from '../../common'
import { Typeahead } from 'react-bootstrap-typeahead'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setLPBusqueda, setLPResultados } from '../../actions'


const mapStateToProps = (state, ownProps) => {
    return {
        ...state, ...ownProps
    }
}
const mapDispatchToProps = {
    setLPBusqueda, setLPResultados
}

class Listado extends Component {

    state = {
        permiso: true,
        clientes: [],
        fecha: "30",
        pendientes: 'todos',
        idCliente: []
    }


    componentDidMount() {
        if (commons.checkPermission(this.props.userData.permisos, ['VerPedido', 'Administrador'])) {
            this.getClientes()
        } else {
            this.setState({ permiso: false })
        }
    }

    handleSelect = (e) => {
        this.setState({ [e.target.id]: e.target.value },()=>{
            this.props.setLPBusqueda({ cliente: this.state.idCliente, pendientes: this.state.pendientes, fecha: this.state.fecha })
        })
    }

    handleChangeCliente = (e) => {
        this.setState({ idCliente: e })
    }

    handleClickEntregar = (id) => {
        this.props.history.push("/remito/" + id)
    }

    handleClickVer = (id) => {
        this.props.history.push("/verPedido/" + id)
    }

    handleClickRemitos = (id) => {
        this.props.history.push("/listarRemitos/" + id)
    }

    getClientes = async () => {
        const body = ({ id: 'getClientes' })
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            this.setState({ clientes: response.cliente })
        } else {
            alert("Error ")
        }
    }

    borrarPedido = async (idPedido) => {
        const body = ({ id: 'borrarPedido' , idPedido: idPedido})
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            this.getPedidos()
        } else {
            alert("Error ")
        }
    }

    getPedidos = async () => {
        this.props.setLPBusqueda({ cliente: this.state.idCliente, pendientes: this.state.pendientes, fecha: this.state.fecha })

        const body = ({ id: 'listarPedidos', cliente: this.state.idCliente, pendientes: this.state.pendientes, fecha: this.state.fecha })
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            if (response.pedidos.length === 0) {
                alert("No hay pedidos para esa búsqueda")
            }
            else {
                let totalEntregados = 0
                let totalPedidos = 0
                response.pedidos.forEach(ped => {
                    totalPedidos += ped.cantidad
                    totalEntregados += ped.entregado
                })
                this.props.setLPResultados({ pedidos: response.pedidos, totalEntregados: totalEntregados, totalPedidos: totalPedidos, totalPendientes: totalPedidos - totalEntregados })
            }
        } else {
            alert("Error ")
        }
    }

    render() {
        let { clientes } = this.state

        return (
            <div>

                <div className="mt-2 mb-2 text-center">
                    <h5 className="text-primary">Listado de pedidos</h5>
                </div>

                <div>
                    <div className="container mt-3 mb-3 bg-light">
                        <div className="row">
                            <div className="col-md-3"><small className="text-muted">Cliente</small></div>
                            <div className="col-md-3"><small className="text-muted">Últimos</small></div>
                            <div className="col-md-3"><small className="text-muted">Con pendiente</small></div>
                            <div className="col-md-3"></div>
                        </div>
                        <div className="row p-2">
                            <div className="col-md-3">
                                <Typeahead
                                    onChange={this.handleChangeCliente}
                                    labelKey="nombre"
                                    id="nombreCliente"
                                    options={clientes}
                                    placeholder="Seleccione un cliente.."
                                />
                            </div>
                            <div className="col-md-3">
                                <Form.Control as="select" id="fecha" value={this.props.pedidos.busqueda.fecha} onChange={this.handleSelect}>
                                    <option value="30">30 días</option>
                                    <option value="60">60 días</option>
                                    <option value="90">90 días</option>
                                    <option value="120">120 días</option>
                                </Form.Control>
                            </div>
                            <div className="col-md-3">
                                <Form.Control as="select" id="pendientes" value={this.props.pedidos.busqueda.pendientes} onChange={this.handleSelect}>
                                    <option value="todos">Todos</option>
                                    <option value="si">Si</option>
                                    <option value="no">No</option>
                                </Form.Control>
                            </div>
                            <div className="col-md-3">
                                <button className="btn btn-primary btn-sm" onClick={this.getPedidos}>Buscar</button>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-3 mb-3">
                        <div className={this.props.pedidos.resultados.totalPendientes > 0 ? "row border border-1 border-danger" : "row border border-1 border-success"}>
                            <div className="col align-self-center text-center">Totales</div>

                            <div className="col">
                                <div className="row"> <div className="col text-center">Pedidos</div> </div>
                                <div className="row"> <div className="col text-center">{this.props.pedidos.resultados.totalPedidos}</div> </div>
                            </div>

                            <div className="col">
                                <div className="row"> <div className="col text-center">Entregados</div> </div>
                                <div className="row"> <div className="col text-center">{this.props.pedidos.resultados.totalEntregados}</div> </div>
                            </div>

                            <div className={this.state.totalPendientes > 0 ? "col bg-danger text-white" : "col bg-success text-white"}>
                                <div className="row"> <div className="col text-center">Pendientes</div> </div>
                                <div className="row"> <div className="col text-center">{this.props.pedidos.resultados.totalPendientes}</div> </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-3 mb-3">
                        <div className="row">
                            <div className="col align-self-center text-center"><h5 className="text-primary">{this.props.pedidos.busqueda.cliente && this.props.pedidos.busqueda.cliente.length>0 ? this.props.pedidos.busqueda.cliente[0]['nombre']: 'Todos los clientes'}</h5></div>
                        </div>
                    </div>

                    <div className="m-2">

                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th>Pedido</th>
                                    <th>Marcas</th>
                                    <th>Fecha</th>
                                    <th className="text-center">Cantidad</th>
                                    <th className="text-center">Entregado</th>
                                    {commons.checkPermission(this.props.userData.permisos, ['VerTotales', 'Administrador']) ?
                                        <Fragment>
                                            <th className="text-center">Lista</th>
                                            <th className="text-center">Total $</th>
                                            <th className="text-center">Descuento %</th>
                                        </Fragment>
                                        : null}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.pedidos.resultados.pedidos.map(n => {
                                    let entregableDisabled = n.cantidad === n.entregado ? true : false
                                    let remitos = 'Remitos (' + n.remitos.toString() + ')'
                                    let borrarDisabled = n.remitos===0 && n.facturas===0? false: true
                                    return (
                                        <tr key={n.idPedido}>
                                            <td>{n.cliente}</td>
                                            <td>{n.idPedido}</td>
                                            <td>{n.marcas}</td>
                                            <td>{n.fecha.split(" ")[0]}</td>
                                            <td className="text-center">{n.cantidad}</td>
                                            <td className="text-center">{n.entregado}</td>
                                            {commons.checkPermission(this.props.userData.permisos, ['VerTotales', 'Administrador']) ?
                                                <Fragment>
                                                    <td className="text-center">{n.nombreLista}</td>
                                                    <td className="text-center">{n.descuento > 0 ? (n.precioTotal / (1 + (n.descuento / 100))).toFixed(2) : n.precioTotal}</td>
                                                    <td className="text-center">{n.descuento}</td>
                                                </Fragment>
                                                : null}
                                            <td>
                                                <button className="btn btn-info btn-sm" disabled={!this.state.permiso} onClick={e => { this.handleClickVer(n.idPedido) }}>Ver</button>
                                                <button className="btn btn-success btn-sm" disabled={entregableDisabled} onClick={e => { this.handleClickEntregar(n.idPedido) }}>Entregar</button>
                                                <button className="btn btn-info btn-sm" disabled={!this.state.permiso} onClick={e => { this.handleClickRemitos(n.idPedido) }}>{remitos}</button>
                                                {borrarDisabled?null:
                                                <button className="btn btn-danger btn-sm" disabled={borrarDisabled} onClick={e => { this.borrarPedido(n.idPedido) }}>Borrar</button>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>

                </div>
            </div>
        )
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Listado))
