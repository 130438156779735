import React, { Component } from 'react'
import { Form, Col, Button, Row } from 'react-bootstrap'
import commons from '../../common'
import MD5 from 'md5'

class ModificarUsuario extends Component{

    state = {
        nombre : '',
        usuario : '',
        clave : '',
        confirmacion : '',
        permiso: true,
        comision: '',
        usuarios: []
    }

    handleSelect = (e) => {
        e.persist()
        this.setState({[e.target.id] : e.target.value},()=>{
            if(e.target.id==='idUsuario'){
                this.state.usuarios.forEach(el=>{
                    if(el.idUsuario===parseInt(e.target.value)){
                        this.setState({
                            nombre: el.nombre,
                            usuario: el.usuario,
                            comision: el.comision,
                            habilitado: el.habilitado
                        })
                    }
                })
            } 
        })
    }

    toggleHabilitado = () => {
    let { habilitado } = this.state
        habilitado = !habilitado
        this.setState({habilitado})
    }

    obtenerUsuarios = async () => {
        const body = { id: 'obtenerUsuarios', idUsuario : this.props.userData.idUsuario }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ usuarios: response.usuarios })
        } else {
            alert("Error ")
        }
    }

    componentDidMount() {
        if (!commons.checkPermission(this.props.userData.permisos,['Administrador'])) {
            this.setState({ permiso: false})
        } else {
            this.obtenerUsuarios()
        }
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleClickActualizar = async() =>{
        let ob = {
            id: 'modificarUsuario',
            nombre : this.state.nombre,
            usuario : this.state.usuario,
            idUsuario: this.state.idUsuario,
            comision: this.state.comision,
            habilitado: this.state.habilitado
        }
        if(this.state.clave.length>0)  {
            if(this.state.clave !== this.state.confirmacion){
                alert("Las claves no coinciden")
                return
            } else {
                    let pass = MD5(this.state.clave)
                    ob.pass = pass
                }
        } 
        let response = await commons.doPost(this.props.token, ob)
        if(response.result === 'ok'){
            alert("Usuario actualizado correctamente")
        } else {
            alert("Error : No se ha podido completar el cambio")
        }
    }

    render(){

        return(
            <div>
                <div className="mt-3">
                    <h5 className="text-primary text-center">
                        Modificar usuario
                    </h5>
                </div>

                <div className="container">

                    <div className="row">
                        <div className="col">
                        <Form.Control className="mt-3 mb-3" as="select" id="idUsuario" required onChange={this.handleSelect} value={this.state.idUsuario}>
                            <option>Seleccione</option>
                            {
                                this.state.usuarios.map(n=>{
                                    return(<option key={n.idUsuario} value={n.idUsuario}>{n.usuario}</option>)
                                })} 
                        </Form.Control>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group as={Row} >
                            <Form.Label column sm={2}>
                                Habilitado
                            </Form.Label>
                            <Col sm="8">
                                {this.state.habilitado? 'Si' : 'No'}
                            </Col>
                            <Col sm="2">
                                <button className="btn btn-info" onClick={this.toggleHabilitado}>Cambiar</button>
                            </Col>

                        </Form.Group>
                        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group as={Row} >
                            <Form.Label column sm={2}>
                                Nombre
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control  name="nombre" value={this.state.nombre} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>
                        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group as={Row} >
                            <Form.Label  column sm={2}>Usuario</Form.Label>
                            <Col sm="10">
                                <Form.Control  name="usuario" value={this.state.usuario }onChange={this.handleChange} />
                            </Col>
                        </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group as={Row} >
                            <Form.Label  column sm={2}>Comisión</Form.Label>
                            <Col sm="10">
                                <Form.Control  type="number" name="comision" value={this.state.comision} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group as={Row} >
                            <Form.Label  column sm={2}>Clave</Form.Label>
                            <Col sm="10">
                                <Form.Control  type="password" value={this.state.clave} name="clave" onChange={this.handleChange} />
                            </Col>
                        </Form.Group></div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group as={Row} >
                            <Form.Label  column sm={2}>Confirmar clave</Form.Label>
                            <Col sm="10">
                                <Form.Control  type="password" value={this.state.confirmacion} name="confirmacion" onChange={this.handleChange} />
                            </Col>
                        </Form.Group>
                        </div>
                    </div>
                        <div className="row justify-content-around">
                            <Form.Row>
                                <div>
                                    <Col>
                                        {this.state.permiso?
                                        <Button onClick={this.handleClickActualizar} variant="secondary" size="sm" block disabled={!this.state.permiso}>
                                            Actualizar
                                        </Button>:<p>No posee permisos para realizar esta acción</p>
                                        }
                                    </Col>
                                </div>
                            </Form.Row>
                        </div>
                </div>
            </div>
        )
    }
}

export default ModificarUsuario
