import React, { Component } from 'react'
import { Button, Form, ProgressBar } from 'react-bootstrap'
import MenuPrincipal from '../Menu'
import MD5 from 'md5'
import { withRouter } from 'react-router-dom'

class Login extends Component {

  state = {
    usuario: '',
    password: ''
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit = async (f) => {
    f.preventDefault()
    let hashed = MD5(this.state.password)
    await this.props.login({ id: 'login', usuario: this.state.usuario, clave: hashed })
  }

    componentDidMount(){
        this.props.history.push("/")
    }

  render() {
    if (this.state.logueado) {
      console.log("logueado")
      return <MenuPrincipal />
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4 text-center">
                    <Form onSubmit={this.onSubmit}>
                        <Form.Group controlId="formInlineName">
                            <Form.Label>Usuario</Form.Label>{' '}
                            <Form.Control className="text-center" type="text" name="usuario" onChange={this.handleChange} />
                        </Form.Group>{' '}
                        <Form.Group controlId="formInlineEmail">
                            <Form.Label>Clave</Form.Label>{' '}
                            <Form.Control className="text-center" type="password" name="password" onChange={this.handleChange} />
                        </Form.Group>{' '}
                        <Form.Group>
                            {this.props.loading ? <ProgressBar animated={true} now={100} label={this.props.barLabel} /> : null}
                        </Form.Group>{' '}
                        <Button type="submit" className="button-login">Ingresar</Button>
                    </Form>

                </div>
                <div className="col-md-4"></div>
            </div>
        </div>
    )

  }

}


export default withRouter(Login)
