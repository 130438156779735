import React, { Component } from 'react'
import { Form, Table } from 'react-bootstrap'
import commons from '../../common'

class ListarArticulos extends Component {

    state={
        permiso: false,
        listaArticulo : [],
        marcas: [],
        marca: ''
    }

    componentDidMount(){
        if (commons.checkPermission(this.props.userData.permisos,['ListaDeArticulos', 'Administrador'])) {
            this.obtenerMarcas()
            this.setState({ permiso: true})
        } 
    }

    obtenerMarcas = async () => {
        const body = ({ id: 'obtenerMarcas', 
            idUsuario : this.props.userData.idUsuario
        })
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ marcas: response.marcas })
        } else {
            alert("Error ")
        }
    }

    handleChange = async (e) => {
        e.persist()
        this.setState({[e.target.id]: e.target.value}, async ()=>{

            const body = ({ id: 'listarArticulos', idMarca: e.target.value })
            let response = await commons.doPost(this.props.token, body)

            if (response.result === 'ok') {
                this.setState({ listaArticulo: response.articulo })
            } else {
                alert("Error ")
            }
        })
    }

    render() {
        let { listaArticulo } = this.state

        return (
            <div>
                <div className="text-center mt-2">
                    <h5 className="text-primary">Listado de artículos</h5>
                </div>

                {!this.state.permiso?
                <div className="text-center mt-4">
                    <small className="text-muted">Su usuario no tiene permisos para listar artículos</small>
                </div>
                
                :null}

                <div className="container container-fluid d-flex flex-column bg-light mt-3">
                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h6>Marca</h6>
                        </div>
                        <div className="col-md-6">
                            <Form.Control as="select" id="marca" required onChange={this.handleChange} value={this.state.marca}>
                                <option>Seleccione</option>
                                {
                                    this.state.marcas.map(n=>{
                                        return(<option key={n.idMarca} value={n.idMarca}>{n.nombre}</option>)
                                    })} 
                                    </Form.Control>
                                </div>
                            </div>
                        </div>

                        <div className="m-2"> 
                            <Form>
                                <Form.Group>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Código barras</th>
                                                <th>Marca</th>
                                                <th>Descripción</th>        
                                                <th>Talle</th>        
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listaArticulo.map(n => {
                                                return (
                                                    <tr key={n.idArticulo}>
                                                        <td>{n.nombreArticulo}</td>
                                                        <td>{n.codigoDeBarra}</td>
                                                        <td>{n.nombreMarca}</td>
                                                        <td>{n.descripcion}</td>                                
                                                        <td>{n.talle}</td>                                
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
        )
    }
}

export default ListarArticulos
