export const setLPBusqueda = (busqueda) => ({
    type: 'SET_LP_BUSQUEDA',
    busqueda: busqueda
})

export const setLPResultados = (resultados) => ({
    type: 'SET_LP_RESULTADOS_BUSQUEDA',
    resultados: resultados
})

export const setLogin = (login) => ({
    type: 'SET_LOGIN',
    login: login
})

export const logout = () => ({
    type: 'LOGOUT'
})
