import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import commons from './common'
import Login from './Components/Login'
import MenuPrincipal from './Components/Menu'
import AgregarArticulo from './Components/Articulos/AgregarArticulo'
import EliminarArticulo from './Components/Articulos/EliminarArticulo'
import ModificarArticulo from './Components/Articulos/ModificarArticulo'
import ListarArticulos from './Components/Articulos/ListarArticulos'
import Pedido from './Components/Pedidos'
import VerPedido from './Components/Pedidos/ver'
import ListaDePrecios from './Components/ListaDePrecios'
import LPedidos from './Components/Pedidos/listado'
import AgregarCliente from './Components/Clientes/AgregarCliente'
import ModificarCliente from './Components/Clientes/ModificarCliente'
import ListarCliente from './Components/Clientes/ListarCliente'
import AgregarUsuario from './Components/Usuarios/AgregarUsuario'
import ModificarUsuario from './Components/Usuarios/ModificarUsuario'
import ListadoUsuarios from './Components/Usuarios/ListadoUsuarios'
import EmailsUsuarios from './Components/Usuarios/EmailsUsuarios'
import Permisos from './Components/Usuarios/Permisos'
import Notificaciones from './Components/Notificaciones'
import Remito from './Components/Remito'
import ListadoRemitos from './Components/Remito/listado'
import VerRemito from './Components/Remito/ver'
import PendientesPorArticulo from './Components/PendientesPorArticulo'
import PendientesPorCliente from './Components/PendientesPorCliente'
import PendientesPorVendedor from './Components/PendientesPorVendedor'
import RankingArticulos from './Components/RankingArticulos'
import CuentaCorriente from './Components/CuentaCorriente'
import InfoBar from './Components/InfoBar'
import { connect} from 'react-redux'
import { setLogin, logout } from './actions'


const mapStateToProps = (state, ownProps) => {
    return {
        ...state, ...ownProps
    }
}

const mapDispatchToProps = {
  setLogin,
    logout

}

class App extends Component {

  state = {
    loading: null,
    barLabel: ''
  }


  login = async (l) => {
    this.setState({ loading: true, barLabel: 'Enviando credenciales' })
    let logResp = await commons.doPost('login', l)
    
    this.setState({ loading: false, barLabel: '' })
    if (logResp) {
      switch (logResp.result) {
        case "loggedIn":
          let userData = {}
              userData['nivel'] = logResp.usuario.nivel
              userData['usuario'] = logResp.usuario.nombre
              userData['idUsuario'] = logResp.usuario.idUsuario
              userData['permisos'] = logResp.permisos
              this.props.setLogin({ loggedIn: true, token: logResp.token, userData: userData})
          break
        case "loggedOff":
          this.props.logout()
          alert(logResp.message)
          break
        default:
          this.props.logout()
      }
    }
  }

  render() {
    const { loggedIn, token, userData }  = this.props.login
    const { barLabel, loading } = this.state
    return (
      <div className="App">
        {loggedIn ? <MenuPrincipal logout={this.props.logout} token={token} userData={userData} /> : null}
          {loggedIn ? <InfoBar userData={userData}/>: null}
        {loggedIn ? <Switch>

            <Route path="/" exact>
            <div className="container"><div className="row"><div className="col text-center"><img src="644795-200.png" alt=""/></div></div></div> 
            </Route>
          <Route path="/altaDeArticulos" render={() => (<AgregarArticulo token={token} userData={userData}/>)} />
          <Route path="/bajaDeArticulos" render={() => (<EliminarArticulo token={token} userData={userData} />)} />
          <Route path="/modificacionDeArticulos" render={() => (<ModificarArticulo userData={userData} token={token} />)}/>
          <Route path="/altaDePedidos" render={() => (<Pedido userData={userData} token={token} />)} />
          <Route path="/listaDePrecios" render={() => (<ListaDePrecios token={token} userData={userData} />)} />
          <Route path="/listaDeArticulos" render={() => (<ListarArticulos token={token} userData={userData} />)} />
          <Route path="/listadoPedidos" render={() => (<LPedidos token={token} userData={userData} />)} />
          <Route path="/altaDeClientes" render={() => (<AgregarCliente token={token} userData={userData} />)} />
          <Route path="/listarClientes" render={() => (<ListarCliente token={token} userData={userData} />)} />
          <Route path="/modificacionDeClientes" render={() => (<ModificarCliente token={token} userData={userData} />)} />
          <Route path="/altaDeUsuarios" render={() => (<AgregarUsuario token={token} userData={userData} />)} />
          <Route path="/modificacionDeUsuarios" render={() => (<ModificarUsuario token={token} userData={userData} />)} />
          <Route path="/listadoUsuarios" render={() => (<ListadoUsuarios token={token} userData={userData} />)} />
          <Route path="/permisos" render={() => (<Permisos token={token} userData={userData} />)} />
          <Route path="/notificaciones" render={() => (<Notificaciones token={token} userData={userData} />)} />
          <Route path="/remito/:id" render={() => (<Remito token={token} userData={userData} />)} />
          <Route path="/verPedido/:id" render={() => (<VerPedido token={token} userData={userData} />)} />
          <Route path="/listarRemitos/:id" render={() => (<ListadoRemitos token={token} userData={userData} />)} />
          <Route path="/listarRemitos" render={() => (<ListadoRemitos token={token} userData={userData} />)} />
          <Route path="/verRemito/:id" render={() => (<VerRemito token={token} userData={userData} />)} />
          <Route path="/pendientesPorArticulo" render={() => (<PendientesPorArticulo token={token} userData={userData} />)} />
          <Route path="/pendientesPorCliente" render={() => (<PendientesPorCliente token={token} userData={userData} />)} />
          <Route path="/pendientesPorVendedor" render={() => (<PendientesPorVendedor token={token} userData={userData} />)} />
          <Route path="/rankingArticulos" render={() => (<RankingArticulos token={token} userData={userData} />)} />
          <Route path="/cuentaCorriente" render={() => (<CuentaCorriente token={token} userData={userData} />)} />
          <Route path="/emailsUsuarios" render={() => (<EmailsUsuarios token={token} userData={userData} />)} />
        
        </Switch>
          : <Login barLabel={barLabel} loading={loading} {...this.props} login={this.login} loggedIn={loggedIn} />}
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
