import React from 'react'

function InfoBar(props)  {
    return(
        <div className="container-fluid">
            <div className="row bg-light">
                <div className="col text-right">Usuario conectado: {props.userData.usuario}</div>
            </div>
        </div>
    )

}

export default InfoBar
