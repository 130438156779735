import React, { Component } from 'react'
import { Form, Col, Button, Row } from 'react-bootstrap'
import commons from '../../common'

const optionsArt = (n) => {
    return (
        <option key={n.idArticulo} value={n.idArticulo}>{n.nombreArticulo}</option>
    )
}

class ModificarArticulo extends Component {

    state = {
        modificar: false,
        articulos: [],
        marcas: [],
        idArticulo: '',
        marca : '',
        descripcion : '',
        codigoDeBarra : '',
        nombreArticulo: '',
        tipoPaquete: '',
        talle: '',
        permiso: true
    }

    componentDidMount() {
        if (commons.checkPermission(this.props.userData.permisos,['ABMArticulos', 'Administrador'])) {
            this.obtenerMarcas()
        } else {
            this.setState({ permiso: false})
        }
    }

    handleClickVolver = () => {
        this.setState({ volver: true })
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    handleChangeSelect = (e) => {
        e.persist()
        this.setState({[e.target.id] : e.target.value},()=>{
            switch(e.target.id){
                case 'marca':
                    this.getArticulos(e.target.value)
                    break
                case 'idArticulo':
                    this.state.articulos.forEach(_a=>{
                        if(_a.idArticulo===parseInt(e.target.value)){
                            this.setState({
                                codigoDeBarra : _a.codigoDeBarra, 
                                marca : _a.idMarca,
                                descripcion : _a.descripcion,
                                nombreArticulo : _a.nombreArticulo,
                                tipoPaquete: _a.tipoPaquete,
                                talle: _a.talle
                            })
                        }
                    })
                    break
                default:
            }
        })


    }

    handleClickModificar = async () => {
        const body = { 
            id: 'modificarArticulo',
            idArticulo : this.state.idArticulo,
            descripcion : this.state.descripcion,
            codigoDeBarra : this.state.codigoDeBarra,
            idTipoPaquete : this.state.tipoPaquete,
            nombreArticulo: this.state.nombreArticulo,
            talle: this.state.talle
        }

        let response = await commons.doPost(this.props.token, body)

        if(response.result === 'ok') {
            alert("Articulo modificado")
            await this.getArticulos(this.state.marca)
        } else {
            alert("Error ")
        }
    }

    //Traer todos los articulos para ponerlos en el select de articulos
    getArticulos = async (i) => {
        const body = ({ id: 'listarArticulos', idMarca: i })
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ articuloGuardado: true ,articulos: response.articulo })
        } else {
            alert("Error ")
        }

    }

    obtenerMarcas = async () => {
        const body = ({ id: 'obtenerMarcas' })
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ marcas: response.marcas })

        } else {
            alert("Error ")
        }

    }


    render() {
        let { articulos } = this.state
        return (
            <div>
                <div className="text-center mt-2">
                    <h5 className="text-primary">Modificar artículo</h5>
                </div>


                <div className="container container-fluid d-flex flex-column bg-light mt-3">
                    <div className="row p-2">
                        <div className="col-md-4 align-self-center">
                            <h6>Buscar</h6>
                        </div>
                        <div className="col-md-4">
                            <h6><small>Marca</small></h6>
                            <Form.Control as="select" id="marca" onChange={this.handleChangeSelect} value={this.state.marca}>
                                <option>Seleccione</option>
                                {
                                    this.state.marcas.map(n=>{
                                        return(<option key={n.idMarca} value={n.idMarca}>{n.nombre}</option>)
                                    })} 
                                    </Form.Control>
                                </div>
                                <div className="col-md-4">
                                    <h6><small>Artículo</small></h6> 
                                    <Form.Control id="idArticulo" as="select" value={this.state.idArticulo} onChange={this.handleChangeSelect}>
                                        <option>Seleccione...</option>
                                        {articulos.map(n => { return optionsArt(n) })}
                                    </Form.Control>
                                </div>
                            </div>
                        </div>


                <div className="m-2">
                    <Form>

                        <Form.Group as={Row} controlId="tipoPaquete">
                            <Form.Label column sm="2">
                                Cantidad por paquete
                            </Form.Label>
                            <div className='col-list'>
                                <Col>
                                    <Form.Control as="select" onSelect={this.handleSelect} onChange={this.handleChange} value={this.state.tipoPaquete}>
                                        <option value="1">x3</option>
                                        <option value="2">x6</option>
                                        <option value="3">x12</option>
                                    </Form.Control>
                                </Col>
                            </div>
                        </Form.Group>

                        <Form.Group as={Row} controlId="nombreArticulo">
                            <Form.Label column sm="2">Nuevo nombre</Form.Label>
                            <Col sm="10">
                                <Form.Control type="text" value={this.state.nombreArticulo} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="talle">
                            <Form.Label column sm="2">Talle</Form.Label>
                            <Col sm="10">
                                <Form.Control type="text" value={this.state.talle} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="codigoDeBarra">
                            <Form.Label column sm="2">Codigo de barras</Form.Label>
                            <Col sm="10">
                                <Form.Control type="text" value={this.state.codigoDeBarra} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="descripcion">
                            <Form.Label column sm="2">Descripción</Form.Label>
                            <Col sm="10">
                                <Form.Control type="text" value={this.state.descripcion} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <div className="row justify-content-around">
                            <Form.Row>
                                <Col>
                                    {this.state.permiso?
                                    <Button onClick={this.handleClickModificar} variant="secondary" size="sm" block disabled={!this.state.permiso}>
                                        Modificar
                                    </Button>:<p>Su usuario no tiene permisos para modificar artículos</p>
                                    }
                                </Col>

                            </Form.Row>
                        </div>
                    </Form>

                </div>
            </div>

        )

    }

}

export default ModificarArticulo
