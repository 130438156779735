import React, { Component } from 'react'
import commons from '../../common'
import { LinkContainer } from 'react-router-bootstrap'
import { Form } from 'react-bootstrap'

class PendientesPorVendedor extends Component {

    state = {
        permiso: false,
        vendedores: [],
        pendientes: [],
        nombreVendedor: '',
        idVendedor: null
    }

    handleChangeCliente = (e) => {
        let cl
        e.forEach(e => {
            cl = e.idCliente
        })
        this.setState({ idCliente: cl })
        this.state.clientes.forEach(_a => {
            if (_a.idCliente === parseInt(cl)) {
                this.setState({
                    idCliente: _a.idCliente
                })
            }
        })
    }

    refrescar = async () => {
        if (this.state.idCliente !== null && this.state.idCliente !== undefined) {
            this.obtenerPendientesPorVendedor()
        } else {
            alert("Por favor seleccione cliente")
        }
    }

    obtenerUsuarios = async () => {
        const body = { id: 'obtenerUsuarios', "idUsuario" : this.props.userData.idUsuario }
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            this.setState({ vendedores: response.usuarios })
        } else {
            alert("Error ")
        }
    }

    componentDidMount() {
        if (commons.checkPermission(this.props.userData.permisos, ['PendientesPorCliente', 'Administrador'])) {
            this.setState({ permiso: true })
            this.obtenerUsuarios()
        } else {
            this.setState({ permiso: false })
        }
    }

    obtenerPendientesPorVendedor = async () => {
        const body = {
            id: 'pendientesPorVendedor',
            idUsuario: this.props.userData.idUsuario,
            idVendedor: this.state.idVendedor
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ pendientes: response.pendientes })
        } else {
            alert("Error al modificar precio")
        }
    }

    handleClickPDF = async () =>{
        if(this.state.nombreVendedor!=='') {
            window.open("/pdf?vendedor_id="+this.state.idVendedor+"&vendedor_nombre="+this.state.nombreVendedor+"&reporte=pendienteVendedor","_blank")
        }
    }

    handleSelectVendedor = (e) => {
        e.persist()
        let nombreVendedor = ''
        let { vendedores } = this.state
            vendedores.forEach(ve => {
                if(ve.idUsuario===parseInt(e.target.value)) {
                nombreVendedor = ve.nombre
                }
            })
        this.setState({idVendedor: e.target.value, nombreVendedor: nombreVendedor},()=>{
            this.obtenerPendientesPorVendedor()
        })
    }

    render() {
        return (
            <div>
                <div className="mt-2 text-center"><h5 className="text-primary">Pendientes por vendedor</h5></div>
                {!this.state.permiso ?
                    <div className="mt-4 text-center"><small className="text-muted">Su usuario no tiene permisos para ver esta sección</small></div> : null}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col text-center">
                                        <Form.Control as="select" name="listaUsuarios" required onChange={this.handleSelectVendedor} value={this.state.usuario}>
                                            <option value="">Seleccione vendedor</option>
                                            {this.state.vendedores.length>0?
                                                    this.state.vendedores.map(lp=>{
                                                        return <option key={lp.idUsuario} value={lp.idUsuario}>{lp.nombre}</option>
                                                    })
                                            :null}
                                        </Form.Control>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <button className="btn btn-info btn-sm text-center m-2" onClick={this.refrescar}>Buscar</button>
                            <button className="btn btn-info btn-sm text-center m-2" onClick={this.handleClickPDF}>PDF</button>
                        </div>
                    </div>
                    <div className="row bg-light p-2 text-center">
                        <div className="col col-sm-1">Pedido</div>
                        <div className="col col-sm-1">Artículo</div>
                        <div className="col">Descripci&oacute;n</div>
                        <div className="col">Cliente</div>
                        <div className="col col-sm-1">Pendiente</div>
                        <div className="col col-sm-1">Opci&oacute;n</div>
                        <div className="col col-sm-1">D&iacute;as</div>
                    </div>
                    {this.state.pendientes.length > 0 ?
                        this.state.pendientes.map(n => {
                            let _diff = n.diff / 86400
                            return (
                                <div className="row text-center" key={n.rand}>
                                    <div className="col col-sm-1"><LinkContainer to={"/verPedido/" + n.idPedido}><button className="btn btn-info btn-sm">{n.idPedido}</button></LinkContainer></div>
                                    <div className="col col-sm-1"><small>{n.nombreArticulo}</small></div>
                                    <div className="col"><small>{n.descripcion}</small></div>
                                    <div className="col"><small>{n.nombreCliente}</small></div>
                                    <div className="col col-sm-1"><small>{n.pendientes}</small></div>
                                    <div className="col col-sm-1"><small>{n.opcion}</small></div>
                                    <div className="col col-sm-1"><small>{parseInt(_diff)}</small></div>
                                </div>
                            )
                        })
                        : null}
                </div>


            </div>
        )
    }
}

export default PendientesPorVendedor
