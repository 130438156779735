import React, { Component } from 'react'
import { Form, Col, Button, Row } from 'react-bootstrap'
import commons from '../../common'
import MD5 from 'md5'

class AgregarUsuario extends Component{

    state = {
        nombre : '',
        usuario : '',
        clave : '',
        confirmacion : '',
        comision: '0',
        permiso: true
    }

    componentDidMount() {
        if (!commons.checkPermission(this.props.userData.permisos,['Administrador'])) {
            this.setState({ permiso: false})
        }
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleClickAgregar = async() =>{

        if(this.state.clave !== this.state.confirmacion){
            alert("Las claves no coinciden");
        }else{
            let pass = MD5(this.state.clave)
            const body = { 
                    id : 'agregarUsuario',
                    nombre : this.state.nombre,
                    usuario : this.state.usuario,
                    comision: this.state.comision,
                    clave : pass
                }
            let response = await commons.doPost(this.props.token, body)
            if(response.result === 'ok'){
                alert("Usuario agregado correctamente")
            }else{
                alert("Error : No se ha podido agregar el usuario")
            }
        }
    }


    render(){
        return(
            <div>
                <div className="mt-3">
                    <h5 className="text-primary text-center">
                        Nuevo Usuario
                    </h5>
                </div>

                <div className="m-2">
                    <Form>
                        <Form.Group as={Row} >
                            <Form.Label column sm={2}>
                                Nombre
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control  name="nombre" onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} >
                            <Form.Label  column sm={2}>Comisión</Form.Label>
                            <Col sm="10">
                                <Form.Control  type="number" name="comision" onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} >
                            <Form.Label  column sm={2}>Usuario</Form.Label>
                            <Col sm="10">
                                <Form.Control  name="usuario" onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} >
                            <Form.Label  column sm={2}>Clave</Form.Label>
                            <Col sm="10">
                                <Form.Control  type="password" value = {this.state.clave} name="clave" onChange={this.handleChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} >
                            <Form.Label  column sm={2}>Confirmar clave</Form.Label>
                            <Col sm="10">
                                <Form.Control  type="password" value= {this.state.confirmacion} name="confirmacion" onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <div className="row justify-content-around">
                            <Form.Row>
                                <div>
                                    <Col>
                                        {this.state.permiso?
                                        <Button onClick={this.handleClickAgregar} variant="secondary" size="sm" block disabled={!this.state.permiso}>
                                            Agregar
                                        </Button>:<p>No posee permisos para realizar esta acción</p>
                                        }
                                    </Col>
                                </div>
                            </Form.Row>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

export default AgregarUsuario;
