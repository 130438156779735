import React, { Component } from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

class MenuPrincipal extends Component {

    render() {
        return (
                <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
                    <LinkContainer to="/">
                        <Navbar.Brand className="bg-white">
                            <img src="644795-200.png" width="30" height="30" alt=""/>
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <NavDropdown title="Articulos" id="basic-nav-dropdown">                           
                                    <LinkContainer to="/altaDeArticulos">
                                        <NavDropdown.Item>
                                            Nuevo
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/modificacionDeArticulos">
                                        <NavDropdown.Item>
                                            Modificar
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/bajaDeArticulos">
                                        <NavDropdown.Item>
                                            Eliminar
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                <NavDropdown.Divider />

                                <LinkContainer to="/listaDePrecios">
                                    <NavDropdown.Item>Lista de precios</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/listaDeArticulos">
                                    <NavDropdown.Item>Lista de artículos</NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown>
                            <NavDropdown title="Pedidos" id="basic-nav-dropdown">
                                <LinkContainer to="/altaDePedidos">
                                    <NavDropdown.Item>Nuevo</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/listadoPedidos">
                                    <NavDropdown.Item>Listado pedidos</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/listarRemitos">
                                    <NavDropdown.Item>Listado remitos</NavDropdown.Item>
                                </LinkContainer>
                                <NavDropdown.Divider />
                                <LinkContainer to="/pendientesPorArticulo">
                                    <NavDropdown.Item>Pendientes por artículo</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/pendientesPorCliente">
                                    <NavDropdown.Item>Pendientes por cliente</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/pendientesPorVendedor">
                                    <NavDropdown.Item>Pendientes por vendedor</NavDropdown.Item>
                                </LinkContainer>
                                <NavDropdown.Divider />
                                <LinkContainer to="/rankingArticulos">
                                    <NavDropdown.Item>Ranking de artículos</NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown>
                            <NavDropdown title="Clientes" id="basic-nav-dropdown"> 
                                <LinkContainer to="/altaDeClientes">
                                    <NavDropdown.Item>Nuevo</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/modificacionDeClientes">
                                    <NavDropdown.Item>Modificar</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/listarClientes">
                                    <NavDropdown.Item>Listado</NavDropdown.Item>
                                </LinkContainer>
                                <NavDropdown.Divider />
                                <LinkContainer to="/cuentaCorriente">
                                    <NavDropdown.Item>Cuenta corriente</NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown>
                            <NavDropdown title="Sistema" id="basic-nav-dropdown"> 
                                <LinkContainer to="/permisos">
                                    <NavDropdown.Item>Permisos</NavDropdown.Item>
                                </LinkContainer>
                                <NavDropdown.Divider />
                                <LinkContainer to="/altaDeUsuarios">
                                    <NavDropdown.Item>Nuevo usuario</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/modificacionDeUsuarios">
                                    <NavDropdown.Item>Modificar usuario</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/listadoUsuarios">
                                    <NavDropdown.Item>Listado usuarios</NavDropdown.Item>
                                </LinkContainer>
                                <NavDropdown.Divider />
                                <LinkContainer to="/emailsUsuarios">
                                    <NavDropdown.Item>Emails usuarios</NavDropdown.Item>
                                </LinkContainer>
                                <NavDropdown.Divider />
                                <LinkContainer to="/notificaciones">
                                    <NavDropdown.Item>Notificaciones</NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown>
                        </Nav>
                          <Nav>
                              {/*
                            <NavDropdown title="Mis datos" id="basic-nav-dropdown">
                                <LinkContainer to="/usuarios">
                                    <NavDropdown.Item>Administración</NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown>
                            */}
                            <Nav.Link eventKey={1} href="#" onClick={this.props.logout}>
                                Salir
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
        )
    }

}


export default MenuPrincipal
