import React, { Component } from 'react'
import { Form, Col, Button, Row} from 'react-bootstrap'
import commons from '../../common'

class AgregarCliente extends Component{

    state = {
        permiso: true,
        nombre : '',
        email : '',
        telefono : '',
        cuit : '',
        descripcion : '',
        vendedor: '',
        usuarios: []
    }

    componentDidMount(){
        if(!commons.checkPermission(this.props.userData.permisos,['Administrador'])){
            this.setState({permiso : false})
        } else {
        this.obtenerUsuarios()
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    obtenerUsuarios = async () => {
        const body = { id: 'obtenerUsuarios', idUsuario : this.props.userData.idUsuario }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ usuarios: response.usuarios })
        } else {
            alert("Error ")
        }
    }

    handleClickAgregar = async() =>{
        if(this.state.nombre!=='' && this.state.cuit!=='' && this.state.vendedor!==''){
            const body = { 
                id: 'agregarCliente',
                nombre : this.state.nombre,
                email: this.state.email,
                telefono: this.state.telefono,
                cuit : this.state.cuit, 
                descripcion : this.state.descripcion,
                vendedor: this.state.vendedor
            }

            let response = await commons.doPost(this.props.token, body)

            if(response.result === 'ok'){
                alert("Cliente agregado correctamente")
            }else{
                alert("Error : No se ha podido agregar el cliente")
            }
        } else {
        alert("No puede agregar un cliente si no tiene nombre, cuit y vendedor asignado")
        }

    }

    render(){
        return(
            <div>
                <div className="mt-2 mb-2 text-center">
                    <h5 className="text-primary">Nuevo cliente</h5>
                </div>


                <div className="m-2">
                    <Form>
                        <Form.Group as={Row} >
                            <Form.Label column sm={1}>Nombre</Form.Label>
                            <Col sm="10">
                                <Form.Control  name="nombre" onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label  column sm={1}>Email</Form.Label>
                            <Col sm="10">
                                <Form.Control  name="email" onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label  column sm={1}>Teléfono</Form.Label>
                            <Col sm="10">
                                <Form.Control  name="telefono" onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} >
                            <Form.Label  column sm={1}>CUIT</Form.Label>
                            <Col sm="10">
                                <Form.Control  name="cuit" onChange={this.handleChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} >
                            <Form.Label  column sm={1}>Descripción</Form.Label>
                            <Col sm="10">
                                <Form.Control  name="descripcion" onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} >
                            <Form.Label column sm={1}>Vendedor</Form.Label>
                            <Col>
                            <Form.Control as="select" name="vendedor" onChange={this.handleChange} value={this.state.vendedor}>
                                <option>Seleccione</option>
                                {
                                    this.state.usuarios.map(n=>{
                                        return(<option key={n.idUsuario} value={n.idUsuario}>{n.usuario}</option>)
                                    })} 
                                    </Form.Control>
                                </Col>
                                </Form.Group>

                        <div className="row justify-content-around">
                            <Form.Row>

                                <div>
                                    <Col>
                                        {this.state.permiso?
                                            <Button onClick={this.handleClickAgregar} variant="secondary" size="sm" block>
                                                Agregar
                                            </Button>:<p>No posee permisos para realizar esta accion</p>
                                        }
                                    </Col>
                                </div>
                            </Form.Row>
                        </div>

                    </Form>
                </div>
            </div>
        )
    }
}

export default AgregarCliente
