import React,{Component} from 'react'
import commons from '../../common'
import { Form } from 'react-bootstrap'

class Notificaciones extends Component {

    state = {
        permiso: true,
        notificaciones: [],
        usuarios: [],
        mailsUsuario: [],
        topics: [{'nombre':'nuevoPedido'},{'nombre':'nuevoRemito'}],
        topic: '',
        idUsuario: '',
        userEmail: '',
        email: null
    }

    handleSelect = (e) => {
        e.persist()
        this.setState({[e.target.id]: e.target.value })
        if(e.target.id === 'idUsuario') {
            this.state.usuarios.forEach(us => {
                if(us.idUsuario === parseInt(e.target.value)) {
                    this.setState({mailsUsuario: us.emails})
                }
            })    
        }
    }

    actualizarNotificaciones = async (d,param1) => {

        const body = { id: 'actualizarNotificaciones', 
            idUsuario: d==='suscribir' ? this.state.idUsuario : param1,
            topic: this.state.topic,
            email: this.state.userEmail,
            accion: d
        }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            await this.obtenerNotificaciones()
        } else {
            alert("Error ")
        }
    }

    obtenerNotificaciones = async () => {
        const body = { id: 'obtenerNotificaciones' }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            let notificaciones = response.notificaciones
            notificaciones.forEach(not=>{
                this.state.usuarios.forEach(us=>{
                    if(not.endpoint === us.email){
                        not.usuario = us.usuario
                        not.nombreUsuario = us.nombre
                    }
                })
            })
            this.setState({ notificaciones: notificaciones })
        } else {
            alert("Error ")
        }
    }

    obtenerUsuarios = async () => {
        const body = { id: 'obtenerUsuarios', idUsuario : this.props.userData.idUsuario }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ usuarios: response.usuarios },()=>{
            this.obtenerNotificaciones()
            })
        } else {
            alert("Error ")
        }
    }

    componentDidMount() {
        if (commons.checkPermission(this.props.userData.permisos, ['Administrador'])) {
            this.obtenerUsuarios()
        } else {
            this.setState({ permiso: false })
        }
    }
    render(){

    return(
        <div>

            <div className="mt-3">
                <h5 className="text-center text-primary">Notificaciones</h5>
            </div>
            <div className="container mt-4 mb-4 border border-info p-3">
                <div className="row">
                    <div className="col"><small className="text-center">Usuario</small></div>
                    <div className="col"><small className="text-center">Email</small></div>
                    <div className="col"><small className="text-center">Canal</small></div>
                    <div className="col"><small className="text-center">Acción</small></div>
                </div>
                <div className="row">
                    <div className="col">
                        <Form.Control as="select" id="idUsuario" required onChange={this.handleSelect} value={this.state.idUsuario}>
                            <option>Seleccione</option>
                            {
                                this.state.usuarios.map(n=>{
                                    return(<option key={n.idUsuario} value={n.idUsuario}>{n.usuario}</option>)
                                })} 
                                </Form.Control>
                            </div>
                    <div className="col">
                        <Form.Control as="select" id="userEmail" required onChange={this.handleSelect} value={this.state.userEmail}>
                            <option>Seleccione</option>
                            {
                                this.state.mailsUsuario.map(ue=>{
                                    return(<option key={ue} value={ue}>{ue}</option>)
                                })} 
                                </Form.Control>
                            </div>
                            <div className="col">
                                <Form.Control as="select" id="topic" required onChange={this.handleSelect} value={this.state.topic}>
                                    <option>Seleccione</option>
                                    {
                                        this.state.topics.map(n=>{
                                            return(<option key={n.nombre} value={n.nombre}>{n.nombre}</option>)
                                        })} 
                                </Form.Control>

                            </div>
                    <div className="col">
                        <button className="btn btn-success btn-sm" onClick={()=>{this.actualizarNotificaciones('suscribir')}}>Asociar</button>
                    </div>
                </div>
            </div>

            <div className="container p-3">
                <div className="row">
                    <div className="col text-center">
                        <button className="btn btn-primary btn-sm" onClick={()=>{this.obtenerNotificaciones()}}>Refrescar</button></div>
                </div>
            </div>
            <div className="container">
                <div className="row bg-light">
                    <div className="col"><small>Usuario</small></div>
                    <div className="col"><small>Email</small></div>
                    <div className="col"><small>Canal</small></div>
                    <div className="col"><small>Acción</small></div>
                </div>
                {this.state.notificaciones.map(n=>{
                    return (
                        <div className="row text-muted" key={n.id}>
                    <div className="col">{n.usuario}</div>
                    <div className="col">{n.email}</div>
                    <div className="col">{n.topic}</div>
                    <div className="col"><button className="btn btn-danger btn-sm" onClick={()=>{this.actualizarNotificaciones('desuscribir',n.id)}}>Desasociar</button>
                </div>

                </div>
                    )
                })}
            </div>
        </div>
    )

    }

}

export default Notificaciones
