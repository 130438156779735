import React, { Component } from 'react'
import { Form, Col, Button, Row } from 'react-bootstrap'
import commons from '../../common'


/*
Hooks React
function AgregarArticulo() {
    const [permiso, setPermiso] = useState(true)
    useEffect(()=>console.log('permiso changed: ' + permiso),[permiso,tipoPaquete])
    setPermiso(false)
}*/

class AgregarArticulo extends Component{

    state = {
        permiso: true,
        tipoPaquete : '1',
        nombreArticulo: '',
        codBarra : '',
        marca : '',
        descripcion : '',
        talle: '',
        marcas: []
    }


    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    handleSelect = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    obtenerMarcas = async() => {
        const body = { 
            id: 'obtenerMarcas',
            idUsuario : this.props.userData.idUsuario
        }
        let response = await commons.doPost(this.props.token, body)

        if(response.result === 'ok'){
            this.setState({marcas: response.marcas})
        }else{
            alert("Error : No se ha podido guardar el articulo")
        }
    }

    guardarArticulo = async() => {
        const body = { 
            id: 'agregarArticulo',
            idUsuario : this.props.userData.idUsuario,
            idTipoPaquete: 3,
            codigoDeBarra: this.state.codBarra,
            nombreArticulo: this.state.nombreArticulo,
            idMarca : this.state.marca, 
            descripcion : this.state.descripcion,
            talle: this.state.talle
        }
        let response = await commons.doPost(this.props.token, body)

        if(response.result === 'ok'){
            alert("Articulo agregado correctamente")
        }else{
            alert("Error : No se ha podido guardar el articulo")
        }

    }

    handleClickVolver = () => {
        this.setState({ volver: true })
    }

    handleClickAceptar = () => {
        this.setState({ aceptar: true })
        this.guardarArticulo()
    }

    componentDidMount(){
        if (commons.checkPermission(this.props.userData.permisos,['ABMArticulo', 'Administrador'])) {
            this.obtenerMarcas()
        } else {
            this.setState({ permiso: false})
        }
    }

    render() {
        return (
            <div>
                <div>
                    <h5 className="text-center mt-2 text-primary">
                        Agregar Artículo
                    </h5>
                </div>

                <div className="m-2">
                    <Form onSubmit={this.onSubmit}>
                        {/*
                        <Form.Group as={Row} controlId="formListaPaq">
                            <Form.Label column sm="2">
                                Cantidad por paquete
                            </Form.Label>
                            <div>
                                <Col>
                                    <Form.Control as="select" name="tipoPaquete" required onSelect={this.handleSelect} onChange={this.handleChange} value={this.state.tipoPaquete}>
                                        <option value="1">x3</option>
                                        <option value="2">x6</option>
                                        <option value="3">x12</option>
                                    </Form.Control>
                                </Col>
                            </div>
                        </Form.Group>
                        */}
                        <Form.Group as={Row} controlId="nombreArticulo">
                            <Form.Label column sm="2">
                                Nombre árticulo
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="text" required value={this.state.nombreArticulo} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="talle">
                            <Form.Label column sm="2">
                                Talle
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="text" required value={this.state.talle} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="codBarra">
                            <Form.Label column sm="2">
                                Código de barras
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="text" required value={this.state.codBarra} onChange={this.handleChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="marca">
                            <Form.Label column sm="2">Marca</Form.Label>
                            <div>
                                <Col>
                                    <Form.Control as="select" name="marca" required onSelect={this.handleSelect} onChange={this.handleChange} value={this.state.marca}>
                                        <option>Seleccione</option>
                                        {
                                            this.state.marcas.map(n=>{
                                                return(<option key={n.idMarca} value={n.idMarca}>{n.nombre}</option>)
                                            })} 
                                            </Form.Control>
                                        </Col>
                                    </div>
                                </Form.Group>

                                <Form.Group as={Row} controlId="descripcion">
                                    <Form.Label column sm="2">Descripción</Form.Label>
                                    <Col sm="10">
                                        <Form.Control type="text" required value={this.state.descrpicion} onChange={this.handleChange} />
                                    </Col>
                                </Form.Group>
                                <div className="row justify-content-around">
                                    <Form.Row>
                                        <div>
                                            <Col>
                                            { this.state.permiso ?
                                                <Button onClick={this.handleClickAceptar} variant="secondary" size="sm" disabled={!this.state.permiso}>
                                                    Agregar
                                                </Button>
                                                : <p>Su usuario no tiene permisos para agregar artículos</p> }
                                            </Col>
                                        </div>
                                    </Form.Row>
                        </div>
                    </Form>
                </div>
            </div>
        )


    }

}

export default AgregarArticulo
