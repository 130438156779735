const login = (state = { login: {loggedIn : false}}, action) => {
    switch (action.type) {
        case 'SET_LOGIN':
            return action.login
        case 'LOGOUT':
            let st = {...state}
            st.loggedIn = false
            return st
        default:
            return state
    }
}

export default login

