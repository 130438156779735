import React, { Fragment, Component } from 'react'
import commons from '../../common'
import { Table, Button, Form } from 'react-bootstrap'

class EmailsUsuarios extends Component {

    state = {
        permiso: true,
        usuarios: [],
        mailsUsuarios: [],
        idUsuario: '',
        email: ''
    }

    handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value})
    }

    handleSelect = (e) => {
        this.setState({[e.target.id]: e.target.value})
    }

    obtenerUsuarios = async () => {
        const body = { id: 'obtenerUsuarios', idUsuario: this.props.userData.idUsuario }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ usuarios: response.usuarios })
        } else {
            alert("Error ")
        }
    }

    obtenerMailsUsuarios = async () => {
        const body = { id: 'obtenerMailsUsuarios', idUsuario: this.props.userData.idUsuario }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ mailsUsuarios: response.usuarios })
        } else {
            alert("Error ")
        }
    }

    handleAgregar = async (accion) => {
        const body = { id: 'modificarMailsUsuarios', idUsuario: this.state.idUsuario, email: this.state.email, accion: accion }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ mailsUsuarios: response.usuarios })
        } else {
            alert("Error ")
        }
    }

    handleEliminar = async (data) => {
        const body = { id: 'modificarMailsUsuarios', idUsuario: data.split(":")[0], email: data.split(":")[1], accion: 'eliminar' }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ mailsUsuarios: response.usuarios })
        } else {
            alert("Error ")
        }
    }

    handleActualizar = async () => {
        if (commons.checkPermission(this.props.userData.permisos, ['Administrador'])) {
            this.obtenerUsuarios()
            this.obtenerMailsUsuarios()
        } else {
            this.setState({ permiso: false })
        }
    }

    componentDidMount() {
        this.handleActualizar()
    }

    render() {
        return (
            <Fragment>
                <div className="mt-2">
                    <h5 className="text-primary text-center">
                        Emails usuarios
                </h5>
                    {!this.state.permiso ?
                        <h6 className="text-center text-danger">Su usuario no tiene permisos para ejecutar esta acción</h6>
                        : null}

                    <div className="container">
                        <div className="row text-center p-3 bg-light">
                            <div className="col">Agregar email a usuario</div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Form.Control as="select" id="idUsuario" required onChange={this.handleSelect} value={this.state.idUsuario}>
                                    <option>Seleccione</option>
                                    {
                                        this.state.usuarios.map(n => {
                                            return (<option key={n.idUsuario} value={n.idUsuario}>{n.usuario}</option>)
                                        })}
                                </Form.Control>
                            </div>
                            <div className="col">
                                <Form.Control id="email" placeholder="Email" onChange={this.handleChange} />
                            </div>
                            <div className="col">
                                <Button onClick={() => {this.handleAgregar('agregar')}} variant="success" size="sm" block disabled={!this.state.permiso}>Agregar</Button>
                            </div>
                        </div>
                    </div>

               
                    <div className="mt-4">
                        <Button onClick={this.handleActualizar} variant="primary" size="sm" block disabled={!this.state.permiso}>Actualizar</Button>
                        <Table striped bordered hover size="sm" className="mt-1">
                            <thead>
                                <tr>
                                    <th>Usuario</th>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.mailsUsuarios.map(n => {
                                    const key = n.idUsuario + ":" + n.email
                                    return (
                                        <tr key={key}>
                                            <td>{n.usuario}</td>
                                            <td>{n.nombre}</td>
                                            <td>{n.email}</td>
                                            <td>
                                                <Button onClick={()=>{this.handleEliminar(key)}} variant="danger" size="sm" block disabled={!this.state.permiso}>Borrar</Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
            </div>
            </Fragment >
        )
    }
}

export default EmailsUsuarios
