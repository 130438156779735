import React,{Component,Fragment} from 'react'
import { withRouter } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import commons from '../../common'

class VerPedido extends Component{

    state = {
        pedido: null,
        permiso: true,
        descuento: ''
    }

    buscarPedido = async () => {
        const body = ({ id: 'detallePedido', idPedido: this.props.match.params.id})
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            this.setState({ pedido: response.pedido })
        } else {
            alert("Error ")
        }
    }

    quitarRenglon = async (e) =>{
        const body = ({ id: 'quitarRenglonPedido', idPedido: this.props.match.params.id, idDetallePedido: e})
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            this.setState({ pedido: response.pedido })
        } else {
            alert("Error ")
        }

    }

    aplicarDescuento = async (idPedido) =>{
        const body = ({ id: 'aplicarDescuento', idPedido: this.props.match.params.id, descuento: this.state.descuento})
        let response = await commons.doPost(this.props.token, body)
        if (response.result === 'ok') {
            this.buscarPedido()
        } else {
            alert("Error ")
        }
    }

    onChDescuento = (e) => {
    this.setState({[e.target.name]: e.target.value})
    }

    componentDidMount(){
        if (commons.checkPermission(this.props.userData.permisos,['VerPedido', 'Administrador'])) {
            this.setState({permiso: true})
            this.buscarPedido()
        } else {
            this.setState({ permiso: false})
        }
    }

    handleClickPDF = async () =>{
        if(this.state.nombreVendedor!=='') {
            window.open("/pdf?cliente="+this.state.pedido.header.cliente+"&fecha="+this.state.pedido.header.fecha.split(' ')[0]+"&pedido="+this.state.pedido.header.idPedido+"&reporte=pedido","_blank")
        }
    }

    render(){
        return(
            <div>
                <div className="mt-2 mb-2 text-center">
                    <h5 className="text-primary">Ver pedido</h5>
                </div>

                <div className="m-2">
                    <div className="container mt-3 mb-3">
                        <div className="row bg-light p-2 text-center">
                            <div className="col">Fecha</div>
                            <div className="col">Cliente</div>
                            <div className="col">Pedido</div>
                            {commons.checkPermission(this.props.userData.permisos,['VerTotales','Administrador'])?<div className="col">Importe total</div>:null}
                            {commons.checkPermission(this.props.userData.permisos,['AplicarDescuentos', 'Administrador']) ?
                            <div className="col">Descuento %</div>
                            :null}
                        </div>
                        <div className="row p-2 text-center">
                            <div className="col p-0">
                                {this.state.pedido?this.state.pedido.header.fecha.split(' ')[0]:null}
                            </div>
                            <div className="col p-0">
                                {this.state.pedido?this.state.pedido.header.cliente:null}
                            </div>
                            <div className="col p-0">
                                {this.state.pedido?this.state.pedido.header.idPedido:null}
                            </div>
                            {commons.checkPermission(this.props.userData.permisos,['VerTotales','Administrador'])?<div className="col p-0">{this.state.pedido?this.state.pedido.header.precioTotal:null}</div>:null}
                            {commons.checkPermission(this.props.userData.permisos,['AplicarDescuentos', 'Administrador']) ?
                            <Fragment>
                                <div className="col p-0">
                                    <input type="text" name="descuento" size="5" value={this.state.descuento} onChange={this.onChDescuento}/>
                                    <button className="button button-sm btn-warning" onClick={()=>{this.aplicarDescuento(this.state.pedido.header.idPedido)}}>Aplicar</button>
                                </div>
                        </Fragment>
                            :null}
                        </div>
                        <div className="row text-center mt-2">
                            <div className="col-md-12"><button className="btn btn-primary btn-sm" onClick={()=>{this.props.history.push("/listadoPedidos")}}>Volver</button>
                            <button className="btn btn-info btn-sm" onClick={this.handleClickPDF}>PDF</button></div>
                        </div>
                    </div>
                </div>

                <div className="m-2">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Marca</th>
                                <th>Artículo</th>
                                <th>Opcion</th>
                                <th>Precio unitario</th>
                                <th>Pedido</th>
                                <th>Entregado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.pedido? 
                                    this.state.pedido.items.map(n=>{
                                        return (
                                            <tr key={n.articulo}>
                                                <td>{n.marca}</td>
                                                <td>{n.articulo}</td>
                                                <td>{n.opcion}</td>
                                                <td>{n.precioUnitario}</td>
                                                <td>{n.cantidad}</td>
                                                <td>{n.entregado}</td>
                                                <td><button className="btn btn-sm btn-danger" onClick={()=>{ this.quitarRenglon(n.idDetallePedido)}}>Borrar</button></td>
                                            </tr>
                                        )
                                    })
                            : null}
                        </tbody>
                    </Table>
                </div>

            </div>
        )}

}

export default withRouter(VerPedido)
