import React,{Component} from 'react'
import commons from '../../common'
import { Form } from 'react-bootstrap'

class RankingArticulos extends Component {

    state = {
        permiso: false,
        ranking: [],
        ordenarPor: 'porPedidos'
    }
    componentDidMount(){
        if (commons.checkPermission(this.props.userData.permisos,['RankingArticulos', 'Administrador'])) {
            this.setState({permiso: true})
            this.rankingArticulos()
        }    
    }

    rankingArticulos = async () => {
        const body = {  
            id: 'rankingArticulos',
            idUsuario : this.props.userData.idUsuario
        }

        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ranking: response.ranking})
        } else {
            alert("Error al obtener ranking")
        }
    }

    handleSelect = (e) =>{
        e.persist()
    this.setState({[e.target.name]:e.target.value})

    let {ranking} = this.state
    ranking.sort(this.compare)
    this.setState({ranking})
    }
    compare = ( a, b ) => {
        let field;
        switch(this.state.ordenarPor)
        {
            case 'porPedidos':
                field = 'pedidos'
                break;
            case 'porEntregados':
                field = 'entregados'
                break;
            default:
        }
        if ( a[field] < b[field] ){
            return -1;
        }
        if ( a[field] > b[field] ){
            return 1;
        }
        return 0;
    }


    render(){
        return(
            <div>
                <div className="mt-2 text-center"><h5 className="text-primary">Ranking artículos</h5></div>
                {!this.state.permiso?
                <div className="mt-4 text-center"><small className="text-muted">Su usuario no tiene permisos para ver esta sección</small></div>:null}

                <div className="container">
                    <div className="row p-3 text-center">
                        <div className="col">
                                        <Form.Control as="select" name="ordenarPor" required onChange={this.handleSelect} value={this.state.ordenarPor}>
                                            <option value="porPedidos">Más pedidos</option>
                                            <option value="porEntregados">Más entregados</option>
                                        </Form.Control>
                        </div>
                    </div>
                    <div className="row bg-light p-2 text-center">
                        <div className="col">Artículo</div>
                        <div className="col">Marca</div>
                        <div className="col">Talle</div>
                        <div className="col">Pedidos</div>
                        <div className="col">Entregados</div>
                    </div>
                    {this.state.ranking.length>0?
                    this.state.ranking.map(n=>{
                        let key = n.idArticulo+":"+n.talle
                        return (
                            <div className="row text-center" key={key}>
                                <div className="col">{n.nombre}</div>
                                <div className="col">{n.marca}</div>
                                <div className="col">{n.talle}</div>
                                <div className="col">{n.pedidos}</div>
                                <div className="col">{n.entregados}</div>
                            </div>
                        )
                    }) 
                    :null}
                </div>


            </div>
        )
    }
}

export default RankingArticulos
