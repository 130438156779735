import React, { Component,Fragment } from 'react'
import { Col, Form, Button, Table} from 'react-bootstrap'
import commons from '../../common'

const optionsArt = (n) => {
    return (
        <option key={n.idArticulo} value={n.idArticulo}>{n.nombreArticulo}</option>
    )
}

class EliminarArticulo extends Component{

    state = {
        permiso: true,
        idArticulo : '',
        idMarca : '',
        descripcion : '',
        codigoDeBarra : '',
        nombreArticulo: '',
        articulos: [],
        marcas: []
    }

    handleClickVolver = () => {
        this.setState({ volver: true })
    }


    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }


    handleClickEliminar = async () => {
        const body = ( { id : 'eliminarArticulo', idArticulo : this.state.idArticulo } )
        let response = await commons.doPost(this.props.token, body)

        if(response.result === 'ok'){
            alert("Articulo Eliminado")
            this.obtenerArticulos()
        }else{
            alert("Error ")
        }
    }

    handleChangeSelect = (e) => {
        e.persist()
        this.setState({ [e.target.id]: e.target.value },()=>{
            switch(e.target.id){
                case 'idMarca':
                    this.obtenerArticulos(this.state.idMarca)
                    break
                case 'idArticulo':
                    this.state.articulos.forEach(_a=>{
                        if(_a.idArticulo===parseInt(e.target.value)){
                            this.setState({
                                codigoDeBarra : _a.codigoDeBarra, 
                                idMarca : _a.idMarca,
                                nombreMarca: _a.nombreMarca,
                                descripcion : _a.descripcion,
                                nombreArticulo: _a.nombreArticulo,
                                idArticulo: _a.idArticulo
                            })
                        }
                    })
                    break
                default:
            }
        })
    }

    componentDidMount(){
        if (commons.checkPermission(this.props.userData.permisos,['ABMArticulos', 'Administrador'])) {
            this.obtenerMarcas()
        } else {
            this.setState({ permiso: false})
        }
    }

    obtenerMarcas = async () => {
        const body = ({ id: 'obtenerMarcas' })
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ marcas: response.marcas})
        } else {
            alert("Error ")
        }
    }

    obtenerArticulos = async () => {
        const body = ({ id: 'listarArticulos', idMarca: this.state.idMarca })
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ articulos: response.articulo, idArticulo: '', marca: '', descripcion: '',codigoDeBarra: '',nombreArticulo: ''  })
        } else {
            alert("Error ")
        }
    }

    render(){
        let { articulos } = this.state

        return(
            <div>
                <div className="text-center mt-2">
                    <h5 className="text-primary">Eliminar artículo</h5>
                </div>
                <div className="container container-fluid d-flex flex-column bg-light mt-3 mb-3">
                    <div className="row p-2">
                        <div className="col-md-4 align-self-center">
                            <h6>Buscar</h6>
                        </div>
                        <div className="col-md-4">
                            <h6><small>Marca</small></h6>
                            <Form.Control as="select" id="idMarca" onChange={this.handleChangeSelect} value={this.state.idMarca}>
                                <option>Seleccione</option>
                                {
                                    this.state.marcas.map(n=>{
                                        return(<option key={n.idMarca} value={n.idMarca}>{n.nombre}</option>)
                                    })} 
                                    </Form.Control>
                                </div>
                                <div className="col-md-4">
                                    <h6><small>Artículo</small></h6> 
                                    <Form.Control id="idArticulo" as="select" value={this.state.idArticulo} onChange={this.handleChangeSelect}>
                                        <option>Seleccione...</option>
                                        {articulos.map(n => { return optionsArt(n) })}
                                    </Form.Control>
                                </div>
                            </div>
                        </div>


                        {this.state.idArticulo?

                        <Fragment>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Nombre árticulo</th>
                                        <th>Código de Barra</th>
                                        <th>Marca</th>
                                        <th>Descripción</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>{this.state.nombreArticulo}</td>
                                        <td>{this.state.codigoDeBarra}</td>
                                        <td>{this.state.nombreMarca}</td>
                                        <td>{this.state.descripcion}</td>
                                    </tr>
                                </tbody>
                            </Table>


                            <div className="row justify-content-around">
                                <Form.Row>
                                    <div>
                                        <Col>
                                            {this.state.permiso ?
                                            <Button onClick={this.handleClickEliminar} variant="secondary" size="sm" block>
                                                Eliminar
                                            </Button>
                                                    : <p>Su usuario no tiene permisos para eliminar artículos</p>
                                            }
                                        </Col>
                                    </div>
                                </Form.Row>
                            </div>
                        </Fragment>

                        : null}
                    </div>
        )
    }


}

export default EliminarArticulo
