import React, { Component } from 'react'
import commons from '../../common'
import { Form } from 'react-bootstrap'

class Permisos extends Component {

    state = {
        permiso: true,
        idUsuario: '',
        usuarios:[],
        permisos:[],
        sPermiso: '',
        usuarioDetalle: null
    }

    verUsuario = () => {
        this.state.usuarios.forEach(el=>{
            if(el.idUsuario === parseInt(this.state.idUsuario)) {
                this.setState({usuarioDetalle: el})
            }
        }) 
    }

    handleSelect = (e) => {
        e.persist()
        this.setState({[e.target.id] : e.target.value},()=>{
            if(e.target.id==='idUsuario'){
            this.verUsuario()
            } 
        })
    }

    obtenerUsuarios = async () => {
        const body = { id: 'obtenerUsuarios', idUsuario : this.props.userData.idUsuario }
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ usuarios: response.usuarios })
        } else {
            alert("Error ")
        }
    }
    obtenerPermisos = async () => {
        const body = ({ id: 'obtenerPermisos', 
            idUsuario : this.props.userData.idUsuario
        })
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            this.setState({ permisos: response.permisos })
        } else {
            alert("Error ")
        }
    }

    modificarPermiso = async (a,p) => {
        const body = ({ id: 'modificarPermiso', 
            idUsuario : this.props.userData.idUsuario,
            accion: a,
            usuario: this.state.idUsuario,
            permiso: p?p:this.state.sPermiso
        })
        let response = await commons.doPost(this.props.token, body)

        if (response.result === 'ok') {
            await this.obtenerUsuarios()
            this.verUsuario()
        } else {
            alert("Error ")
        }
    }


    componentDidMount() {
        if (commons.checkPermission(this.props.userData.permisos, ['Administrador'])) {
            this.obtenerUsuarios()
            this.obtenerPermisos()

        } else {
            this.setState({ permiso: false })
        }
    }
    render() {
        return (
            <div className="mt-2">
                <h5 className="text-primary text-center">
                    Permisos
                </h5>
                {!this.state.permiso?<h6 className="text-muted text-center">Su usuario no tiene permisos para utilizar esta sección</h6>:null}
                <div className="container">
                    <div className="row text-center p-3 bg-light">
                        <div className="col"><small className="text-muted">Usuario</small></div>
                        <div className="col"><small className="text-muted">Permiso</small></div>
                        <div className="col"><small className="text-muted">Acción</small></div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Form.Control as="select" id="idUsuario" required onChange={this.handleSelect} value={this.state.idUsuario}>
                                <option>Seleccione</option>
                                {
                                    this.state.usuarios.map(n=>{
                                        return(<option key={n.idUsuario} value={n.idUsuario}>{n.usuario}</option>)
                                    })} 
                                    </Form.Control>
                                </div>
                                <div className="col">
                                    <Form.Control as="select" id="sPermiso" required onChange={this.handleSelect} value={this.state.sPermiso}>
                                        <option>Seleccione</option>
                                        {
                                            this.state.permisos.map(n=>{
                                                return(<option key={n} value={n}>{n}</option>)
                                            })} 
                                            </Form.Control>
                                        </div>
                                        <div className="col text-center align-self-center">
                                            <button className="btn btn-sm btn-success" disabled={!this.state.permiso} onClick={()=>{this.modificarPermiso('agregar')}}>Agregar</button>
                                        </div>
                                    </div>
                                </div>

                                {this.state.usuarioDetalle?
                                <div className="container mt-3">
                                    <div className="row">
                                        <div className="col text-center bg-light">
                                            <p className="pt-2">Permisos actuales</p>
                                        </div>
                                    </div>
                                    {this.state.usuarioDetalle.permisos.map((n)=>{
                                        return(
                                            <div className="row text-center" key={n}>
                                                <div className="col"><small>{n}</small></div>
                                                <div className="col"><button className="btn btn-sm btn-danger" disabled={!this.state.permiso} onClick={()=>{this.modificarPermiso('quitar',n)}}>Quitar</button></div>
                                            </div>
                                        )
                                    })}
                                
                                </div>
                                
                                :null}
                            </div>
        )
    }
}

export default Permisos
